import {Component, Input, OnInit} from '@angular/core';
import {StatsType} from "../../../interfaces/stats.type";
import {CurrencyPipe} from "@angular/common";
import {PAYMENT_STATUS} from "../../../interfaces/customTypes";

const PAID_STATUS = ['paid', 'ach-inititated']

@Component({
  selector: 'app-credit-tooltip',
  templateUrl: './credit-tooltip.component.html',
  styleUrls: ['./credit-tooltip.component.scss']
})
export class CreditTooltipComponent implements OnInit {
  @Input() stats: Partial<StatsType> | undefined;
  @Input() totalCredits: number = 0;
  @Input({required: true}) toolTipType: PAYMENT_STATUS | 'header' = 'header';
  public credit: string = '';
  public message: string = '';
  public creditItems: {text: string, value: string}[] = [];

  constructor(private currency: CurrencyPipe) { }

  // ngOnInit() {
  //   this.credit = `${this.currency.transform(this.totalCredits, 'USD')} ${PAID_STATUS.includes(this.toolTipType) ? ' in Credit' : ' Credit'}`;
  //   let allItems : any = [
  //     {
  //       text : "Refund Credit",
  //       value: this.currency.transform(this.stats?.refundCredit!, 'USD')
  //     },
  //     {
  //       text : "Referral Credit",
  //       value: this.currency.transform(this.stats?.availableCredits!, 'USD')
  //     },
  //     {
  //       text : "Bonus Credit",
  //       value: this.currency.transform(this.stats?.bonusCredit!, 'USD')
  //     },
  //     {
  //       text : "Showroom Commission",
  //       value: this.currency.transform(this.stats?.showroomCommission!, 'USD')
  //     }
  //
  //   ];
  //
  //
  //   this.creditItems = [];
  //   if(this.stats?.refundCredit! > 0 && this.stats?.availableCredits! > 0 && this.stats?.bonusCredit! > 0) {
  //     this.creditItems = allItems;
  //   }
  //   else if(this.stats?.refundCredit! > 0 && this.stats?.availableCredits! > 0) {
  //     this.creditItems.push(allItems[0], allItems[1]);
  //   }
  //   else if(this.stats?.refundCredit! > 0 && this.stats?.bonusCredit! > 0) {
  //     this.creditItems.push(allItems[0], allItems[2]);
  //   }
  //   else if(this.stats?.availableCredits! > 0 && this.stats?.bonusCredit! > 0) {
  //     this.creditItems.push(allItems[1], allItems[2]);
  //   }
  //   else if(this.stats?.refundCredit! > 0) {
  //     this.credit = `${this.currency.transform(this.stats?.refundCredit!, 'USD')} Refund Credit`;
  //   }
  //   else if(this.stats?.availableCredits! > 0) {
  //     this.credit = `${this.currency.transform(this.stats?.availableCredits!, 'USD')} Referral Credit`;
  //   }
  //   else if(this.stats?.bonusCredit! > 0) {
  //     this.credit = `${this.currency.transform(this.stats?.bonusCredit!, 'USD')} Bonus Credit`;
  //   }
  //   this.message = this.toolTipType === 'header' ? ' that can be applied to your next purchase.' : ' that can be applied to this purchase.';
  // }

  ngOnInit() {
    this.credit = `${this.currency.transform(this.totalCredits, 'USD')} ${PAID_STATUS.includes(this.toolTipType) ? ' in Credit' : ' Credit'}`;
    let allItems: any = [
      {
        text: "Refund Credit",
        value: this.currency.transform(this.stats?.refundCredit!, 'USD')
      },
      {
        text: "Referral Credit",
        value: this.currency.transform(this.stats?.availableCredits!, 'USD')
      },
      {
        text: "Bonus Credit",
        value: this.currency.transform(this.stats?.bonusCredit!, 'USD')
      },
      {
        text: "Showroom Commission",
        value: this.currency.transform(this.stats?.showroomCommission!, 'USD')
      }
    ];

    this.creditItems = [];

    const activeCredits = [
      this.stats?.refundCredit! > 0,
      this.stats?.availableCredits! > 0,
      this.stats?.bonusCredit! > 0,
      this.stats?.showroomCommission! > 0
    ];

    const activeCount = activeCredits.filter(Boolean).length;
      if (activeCount === 1) {
      if (this.stats?.refundCredit! > 0) {
        this.credit = `${this.currency.transform(this.stats?.refundCredit, 'USD')} Refund Credit`;
      } else if (this.stats?.availableCredits! > 0) {
        this.credit = `${this.currency.transform(this.stats?.availableCredits, 'USD')} Referral Credit`;
      } else if (this.stats?.bonusCredit! > 0) {
        this.credit = `${this.currency.transform(this.stats?.bonusCredit, 'USD')} Bonus Credit`;
      } else if (this.stats?.showroomCommission! > 0) {
        this.credit = `${this.currency.transform(this.stats?.showroomCommission, 'USD')} Showroom Commission`;
      }
    } else {
        activeCredits.forEach((isActive, index) => {
              if (isActive) {
                this.creditItems.push(allItems[index]);
              }
            });
      }
    const prefix =  activeCount === 1 && this.stats?.showroomCommission! > 0 ? 'credit' :''
      this.message = this.toolTipType === 'header'
      ? `${prefix} that can be applied to your next purchase.`
      : `${prefix} that can be applied to this purchase.`;
  }

}
