import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {catchError, map, Observable, take} from "rxjs";
import {quote} from "../../interfaces/quote.type";
import {BrandDetails} from "../../interfaces/quoteItem.type";

interface moveProductsToQuote {
  moveTo: string,
  cartIdList: number[],
  quoteId: number
}

interface submitAddProduct {
  url: string,
  productName: string,
  quantity: number,
  variant: string,
  orderId: number,
  unixVersion: number,
}

interface submitEditProduct {
  orderId: number,
  orderItemId: number,
  productName: string,
  url: string,
  variant: string,
  quantity: number,
  unixVersion: number,
}

interface submitDeleteProduct {
  orderId: number,
  orderItemId: number,
  unixVersion: number,
}

interface createNewDraft {
  companyId?: number
}

export interface QuoteDetailsResponse {
  type?: string
  isDifferentCompany?: boolean,
  newCompanyId?: number,
  quote?: quote,
  quoteItems?: BrandDetails[],
  showRequestQuotePopup?: boolean,
  credits?: number,
  bonusCredit?: number
  minoanCredit?: {
    minoanCredit: number,
  },
  showroomCommission?: number,
}

@Injectable({
  providedIn: 'root'
})
export class QuoteService {

  constructor(public http: HttpClient) {
  }

  /**
   * [POST] API to get the list of quotes
   * @param body Request body
   */
  getQuoteList(body: any) {
    return this.http.post(`${environment.apiUrl}/order/quote/list`, body)
      .pipe(take(1), map((response: any) => response.data), catchError((err) => {
        throw err
      }))
  }

  /**
   * [GET] API to get quote detail
   * @param liveQuoteId Quote ID
   */
  getQuoteDetail(liveQuoteId: string): Observable<QuoteDetailsResponse> {
    let params = new HttpParams();
    params = params.append('liveQuoteId', liveQuoteId);
    return this.http.get(`${environment.apiUrl}/order/quote/details`, {params})
      .pipe(take(1), map((response: any) => response.data), catchError((err) => {
        throw err
      }))
  }


  /**
   * [POST] API to move multiple products from cart/wishlist to a quote
   * @param body Request body
   */
  moveProductsFromCartAndWishlistToQuote(body: moveProductsToQuote) {
    return this.http.post(`${environment.apiUrl}/move/cart/item`, body)
      .pipe(take(1), map((response: any) => response.data), catchError((err) => {
        throw err
      }))
  }

  /**
   * [POST] API to create new draft quote
   * @param body Request body
   */
  createNewDraftQuote(body: createNewDraft) {
    return this.http.post(`${environment.apiUrl}/order/quote/draft`, body)
      .pipe(take(1), map((response: any) => response.data), catchError((err) => {
        throw err
      }))
  }

  /**
   * [POST] API to add new item to quote
   * @param body Request body
   */
  submitAddProduct(body: submitAddProduct): Observable<any> {
    return this.http.post(`${environment.apiUrl}/quote/item/add`, body)
      .pipe(take(1), map((response: any) => response.data), catchError((err) => {
        throw err
      }))
  }

  /**
   * [POST] API to edit item to quote
   * @param body Request body
   */
  submitEditProduct(body: submitEditProduct): Observable<any> {
    return this.http.put(`${environment.apiUrl}/quote/item/edit`, body)
      .pipe(take(1), map((response: any) => response.data), catchError((err) => {
        throw err
      }))
  }

  /**
   * [POST] API to delete single product
   * @param body Request body
   */
  submitDeleteSingleProduct(body: submitDeleteProduct) {
    return this.http.post(`${environment.apiUrl}/quote/item/remove`, body)
      .pipe(take(1), map((response: any) => response.data), catchError((err) => {
        throw err
      }))
  }

  /**
   * [POST] API to delete single product
   * @param body Request body
   */
  submitBulkProductsDelete(body: any) {
    return this.http.post(`${environment.apiUrl}/quote/item/multiple/remove`, body)
      .pipe(take(1), map((response: any) => response.data), catchError((err) => {
        throw err
      }))
  }

  /**
   * [POST] API to save shipping address changes on sidebar close
   * @param body Request body
   */
  saveAndExit(body: any) {
    return this.http.post(`${environment.apiUrl}/quote/shipping/update`, body)
      .pipe(take(1), map((response: any) => response.data), catchError((err) => {
        throw err
      }))
  }

  /**
   * [POST] API to create empty quote
   * @param body Request body
   */
  createNewQuote(body: any) {
    return this.http.post(`${environment.apiUrl}/order/quote/draft`, body)
      .pipe(take(1), map((response: any) => response.data), catchError((err) => {
        throw err
      }))
  }

  /**
   * [PUT] API to Archive quote
   * @param body Request body
   */
  archiveQuote(body: any) {
    return this.http.put(`${environment.apiUrl}/order/cancel`, body)
      .pipe(take(1), map((response: any) => response.data), catchError((err) => {
        throw err
      }))
  }

  /**
   * [PUT] API to Request quote from draft
   * @param body Request body
   */
  requestQuoteFromDraft(body: any) {
    return this.http.put(`${environment.apiUrl}/quote/request`, body)
      .pipe(take(1), map((response: any) => response.data), catchError((err) => {
        throw err
      }))
  }

  /**
   * [PUT] API to change notified users and properties of a user
   * @param body Request body
   */
  modifyNotifiedUsersAndProperties(body: any) {
    return this.http.put(`${environment.apiUrl}/order/quote/tag`, body)
      .pipe(take(1), map((response: any) => response.data), catchError((err) => {
        throw err
      }))
  }

  /**
   * [POST] API to move products out of a quote to some other quote, cart, wishlist
   * @param body Request body
   */
  moveBulkProductsFromQuote(body: any) {
    return this.http.post(`${environment.apiUrl}/move/quote/item`, body)
      .pipe(take(1), map((response: any) => response.data), catchError((err) => {
        throw err
      }))
  }

  /**
   * [POST] API to copy products out of an order to some other quote, cart, wishlist
   * @param body Request body
   * */
  moveBulkProductsFromOrder(body: any) {
    return this.http.post(`${environment.apiUrl}/save/order/item`, body)
      .pipe(take(1), map((response: any) => response.data), catchError((err) => {
        throw err
      }))
  }


/**
 * Downloads a receipt or invoice PDF.
 *
 * @param body - Request body containing the order ID and other parameters.
 *
 * @returns An Observable that emits the PDF file as a Blob.
 *
 * The HTTP request is made with custom options:
 * - Headers set to accept text/html and send text/html
 * - Response type set to 'blob'
 *
 * On success, the resulting Blob is emitted from the Observable.
 * On error, the error is caught and rethrown.
 */
  downloadReceiptOrInvoice(body: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'text/html',
        'Content-Type': 'text/html'
      }),
      responseType: 'blob' as 'json'
    };
    return this.http.post(`${environment.apiUrl}/order/pdf/download`, body, httpOptions).pipe(
      map((res: any) => res), catchError(err => {
        throw err
      })
    );
  }

  /**
   * [GET] API to get options for quote and order
   * @param body Request body
   */
  getQuoteOptions(body: any) {
    return this.http.get(`${environment.apiUrl}/order/detail/option/list?orderId=${body.orderId}`)
      .pipe(take(1), map((response: any) => response.data), catchError((err) => {
        throw err
      }));
  }

  /**
   * [GET] API to fetch filters for both quote and order lists
   * @param filterFor Whether to fetch filters for quote list or order list
   */
  getFilters(filterFor: 'order' | 'quote') {
    let params = new HttpParams();
    params = params.append('type', filterFor);
    return this.http.get(`${environment.apiUrl}` + '/order/filter/list', {params})
      .pipe(take(1), map((response: any) => response.data), catchError((err) => {
        throw err
      }));
  }

  /**
   * [GET] API to get order detail
   * @param liveQuoteId Quote/Order ID
   */
  getOrderDetail(liveQuoteId: string) {
    let params = new HttpParams();
    params = params.append('liveOrderId', liveQuoteId);
    return this.http.get(`${environment.apiUrl}/order/info`, {params})
      .pipe(take(1), map((response: any) => response.data), catchError((err) => {
        throw err
      }))
  }

  /**
   * [GET] API to fetch tracking details from Route
   * @param body Request body
   */
  getTrackingDetails(body: any) {
    return this.http.get<any>(`${environment.apiUrl}/shipment/details/?trackingNumber=${body.id}`)
      .pipe(take(1), map((response: any) => response.data), catchError((err) => {
        throw err
      }))
  }

  createCopyOfQuote(body:any){
    return this.http.post(`${environment.apiUrl}/quote/copy`, body).pipe(
      map((res: any) => res.data), catchError(err => {
        throw err
      })
    );
  }

  /**
   * [POST] API to update show request quote popup
   * @param body Request body
   */
  updateShowQuoteRequestPopup(body: any){
    return this.http.post(`${environment.apiUrl}/requestQuotePopup/update`, body)
      .pipe(take(1), map((response : any) => response.data), catchError((err) => {throw err}))
  }

  /**
   * Upload file API call [POST]
   * @param body holds the value of form data created after uploading file
   */
  uploadItemsToQuote(body:any){
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.http.post<any>(`${environment.apiUrl}/quote/item/import/b2b`, body, {headers})
      .pipe(take(1), map((response : any) => response), catchError((err) => {throw err}));
  }

  /**
   * [POST] API to cancel Upload file API call
   */
  cancelUploadItemsToQuote(body:any){
    return this.http.post<any>(`${environment.apiUrl}/revert/item/import`, body)
      .pipe(take(1), map((response : any) => response), catchError((err) => {throw err}));
  }

  /**
   * [POST] API to add New Draft quote
   */
  addNewDraftQuote(body:any){
    return this.http.post<any>(`${environment.apiUrl}/url/copy/draft`, body)
      .pipe(take(1), map((response : any) => response), catchError((err) => {throw err}));
  }
}
