import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, catchError, firstValueFrom, map, Subject, take} from 'rxjs';
import {NewPropertyAdd, selfServeProperty, SHOWROOM_SETUP,} from '../../interfaces/property.type';
import {environment} from 'src/environments/environment';
import {PropertyService} from "./property.service";
import {SpacesService} from "./spaces.service";
import {ProductService} from "./product.service";
import {AuthService} from "../auth/auth.service";
import {PropertySelectionService} from "../base/property-selection.service";
import {ManagementService} from "../management/management.service";
import {
  MAX_PROPERTIES_STEPS,
  MAX_SHARE_STEPS,
  MAX_SPACES_STEPS
} from "../../showroom-self-serve/self-serve-setup/self-serve-footer/self-serve-footer.component";
import {GOOGLE_MAP_RESTRICTIONS} from "../../constants/constants";
import PlaceResult = google.maps.places.PlaceResult;

declare type CurrentStep = 'property' | 'spaces' | 'share' | 'getting-started';

@Injectable({
  providedIn: 'root',
})
export class SelfServeService {
  autocomplete: google.maps.places.AutocompleteService;
  constructor(
    public http: HttpClient,
    public propertyService: PropertyService,
    public productService: ProductService,
    public spaceService: SpacesService,
    public auth : AuthService,
    public propertySelectionService: PropertySelectionService,
    public managementApiService: ManagementService,
  ) {
    this.autocomplete = new google.maps.places.AutocompleteService()
  }

  PROPERTY_ID = new BehaviorSubject<number>(0);
  _PROPERTY_DATA = new BehaviorSubject<selfServeProperty | undefined>(
    undefined
  );

  _SHOWROOM_INFO = new BehaviorSubject<SHOWROOM_SETUP>(
    this.setShowroomSetupPayload()
  );

  SELF_SERVE_PROGRESS = new BehaviorSubject<[number, number, number]>([
    0, 0, 0,
  ]);

  _PROPERTY_PROGRESS = new BehaviorSubject<number>(0);

  _CURRENT_STEP = new BehaviorSubject<CurrentStep>('getting-started');

  _SPACE_PROGRESS = new BehaviorSubject<number>(0);

  _SHARE_PROGRESS = new BehaviorSubject<number>(0);

  _SHOW_PREVIEW = new BehaviorSubject<any>(false);

  _SAVE_AND_EXIT = new BehaviorSubject<any>(false);
  _PREVIEW = new Subject<boolean>();

  _USER_PROPERTY_ACCOUNT_ID = {
    user_id : this.auth.user.getValue()?.account?.id,
    property_id : this.propertyId,
    account_id : this.auth.user.getValue()?.account?.activeCompanyId,
  }
  _SCREEN_NAMES: any = {
    '0,0,0': "property_intro",
    '1,0,0': "property_selection",
    '2,0,0': "property_showroom_name",
    '3,0,0': "property_cover_image",
    '4,0,0': "property_logo_upload",
    '5,0,0': "property_listing_url",
    '6,0,0': "property_address",
    '6,1,0': "spaces_intro",
    '6,2,0': "spaces_select_spaces",
    '6,3,0': "spaces_assign_items",
    '6,4,0': "spaces_our_favorites",
    '6,5,0': "spaces_images_upload",
    '6,5,1': "share_intro",
    '6,5,2': "share_publish",
    '6,5,3': "share_free_touchpoint",
    '6,5,4': "share_more_ways"
  }
  private _NEW_PROPERTY_SETUP: BehaviorSubject<NewPropertyAdd> =
    new BehaviorSubject({
      name: '',
      showroomName: '',
      companyId: 0,
      userAccess: [],
    });

  private _NEXT_BUTTON_DISABLE: BehaviorSubject<boolean> = new BehaviorSubject(
    true
  );

  public USER_DATA = new BehaviorSubject<any>(undefined);

  set propertyId(id: number) {
    this.PROPERTY_ID.next(id);
    if (id) {
      sessionStorage.setItem('propertyId', JSON.stringify(id));
    } else {
      sessionStorage.removeItem('propertyId');
    }
  }

  get propertyId() {
    return this.PROPERTY_ID.getValue();
  }

  get selfServeProgress() {
    return this.SELF_SERVE_PROGRESS.getValue();
  }

  set selfServeProgress(progress: [number, number, number]) {
    this.SELF_SERVE_PROGRESS.next(progress);
  }

  get PROPERTY_PROGRESS(): number {
    return this._PROPERTY_PROGRESS.getValue();
  }

  set PROPERTY_PROGRESS(value: number) {
    this._PROPERTY_PROGRESS.next(value);
  }

  get CURRENT_STEP(): CurrentStep {
    return this._CURRENT_STEP.getValue();
  }

  set CURRENT_STEP(value: CurrentStep) {
    this._CURRENT_STEP.next(value);
  }

  get NEXT_BUTTON_DISABLE(): boolean {
    return this._NEXT_BUTTON_DISABLE.getValue();
  }

  set NEXT_BUTTON_DISABLE(value: boolean) {
    this._NEXT_BUTTON_DISABLE.next(value);
  }

  get propertyData(): any {
    return this._PROPERTY_DATA.getValue();
  }

  set propertyData(value: any) {
    this._PROPERTY_DATA.next(value);
  }

  get showroomSetup(): SHOWROOM_SETUP {
    return this._SHOWROOM_INFO.getValue();
  }

  set showroomSetup(value: SHOWROOM_SETUP) {
    this._SHOWROOM_INFO.next(value);
  }




  get wizardButtonName() {
    if (this.CURRENT_STEP === 'getting-started') {
      return 'Get started';
    }

    if (this.CURRENT_STEP === 'share') {
      return this.SHARE_PROGRESS === 2 ? 'Publish' : this.SHARE_PROGRESS === 4 ? 'Finish' : 'Next';
    }

    return 'Next';
  }

  setShowroomSetupPayload(
    data?: selfServeProperty | undefined
  ): SHOWROOM_SETUP {
    return {
      propertyId: data?.id ? data?.id : 0,
      name: data?.name ? data?.name : '',
      showroomName: data?.showroomName ? data?.showroomName : '',
      landingImage: data?.landingImage ? data?.landingImage : '',
      logo: data?.logo ? data?.logo : '',
      logoLink: data?.logoLink ? data?.logoLink : '',
      address: data?.address ? data?.address : '',
      zipCode: data?.zipCode ? data?.zipCode : '',
      state: data?.state ? data?.state : '',
      stateCode: data?.stateCode ? data?.stateCode : '',
      city: data?.city ? data?.city : '',
      latitude: data?.latitude ? data?.latitude : 0,
      longitude: data?.longitude ? data?.longitude : 0,
    };
  }

  get newPropertySetup(): NewPropertyAdd {
    return this._NEW_PROPERTY_SETUP.getValue();
  }

  set newPropertySetup(value: NewPropertyAdd) {
    this._NEW_PROPERTY_SETUP.next(value);
  }

  get SPACE_PROGRESS(): number {
    return this._SPACE_PROGRESS.getValue();
  }

  set SPACE_PROGRESS(value: number) {
    this._SPACE_PROGRESS.next(value);
  }

  get SHARE_PROGRESS(): number {
    return this._SHARE_PROGRESS.getValue();
  }

  set SHARE_PROGRESS(value: number) {
    this._SHARE_PROGRESS.next(value);
  }

  // Setting showroom preview popup state
  set showPreviewState(value: boolean) {
    this._SHOW_PREVIEW.next(value);
  }

  get userPropertyAccountId(){
    return {
      ...this._USER_PROPERTY_ACCOUNT_ID,
      property_id: this.propertyId
    }
  }

  /**
   * Setting user, account and property_id for amplitude service events
   * @param value
   */
  set userPropertyAccountId(value : any){
    this._USER_PROPERTY_ACCOUNT_ID = value
  }

  /**
   * To save current state in session
   */
  setSessionStorageState() {
    const state = [
      this.PROPERTY_PROGRESS >= MAX_PROPERTIES_STEPS ? MAX_PROPERTIES_STEPS : this.PROPERTY_PROGRESS,
      this.SPACE_PROGRESS >= MAX_SPACES_STEPS ? MAX_SPACES_STEPS : this.SPACE_PROGRESS,
      this.SHARE_PROGRESS >= MAX_SHARE_STEPS ? MAX_SHARE_STEPS : this.SHARE_PROGRESS,
    ];
    sessionStorage.setItem('selfServeState', JSON.stringify(state));
  }

  /**
   * To load self serve state from session
   * @return True if state is restored, else false
   */
  loadSessionState() {
    const state = sessionStorage.getItem('selfServeState');
    if (state) {
      const stateParsed = JSON.parse(state);
      this.PROPERTY_PROGRESS = stateParsed[0] >= MAX_PROPERTIES_STEPS ? MAX_PROPERTIES_STEPS : stateParsed[0];
      this.SPACE_PROGRESS = stateParsed[1] >= MAX_SPACES_STEPS ? MAX_SPACES_STEPS : stateParsed[1];
      this.SHARE_PROGRESS = stateParsed[2] >= MAX_SHARE_STEPS ? MAX_SHARE_STEPS : stateParsed[2];
      return true;
    }
    return false;
  }

  /**
   * [POST] API to publish showroom
   * @param requestBody
   */
  publishShowroom(requestBody: any) {
    return this.http.post(`${environment.apiUrl}/showroom/publish`, requestBody).pipe(
      take(1), map((response: any) => response.data), catchError(err => {
        throw err
      })
    )
  }

  /**
   * Reset showroom setup data
   */
  resetShowroomSetup(isEmptyPropertyList: boolean = true) {
    this.propertyId = 0;
    this.propertyData = undefined;
    this.showroomSetup = this.setShowroomSetupPayload();
    this.SPACE_PROGRESS = 0;
    this.SHARE_PROGRESS = 0;
    this.PROPERTY_PROGRESS = 0;
    this.setSessionStorageState();
    if(isEmptyPropertyList) {
      this.propertyService.PROPERTY_LIST.next([]);
    }
    this.productService.INIT_PRODUCT_LIST.next(null);
    this.spaceService.SPACE_LIST.next([]);
  }

  /**
   * [POST] API to update showroom publish status
   * @param requestBody
   */
  async updateShowroomPublishStatus(requestBody: any) {
    return firstValueFrom(this.publishShowroom(requestBody)).then(async () => {
      this.SHARE_PROGRESS = this.SHARE_PROGRESS + 1
    })
  }

  async getUsersList() {
    const requestBody = {
      status: true,
      propertyId: 0,
      companyId: this.propertySelectionService.selectedCompanyInformation.getValue().companyId,
      order: [{
        columnName: 'name',
        direction: false
      }]
    }
    const response = await firstValueFrom(this.managementApiService.getUsersList(requestBody)).catch((err) => {
      console.log(err)
    })
    if (response) {
      let showDropdownForUserAccess = false;
      const allUsers = response?.accountList;

      const members = allUsers.filter((eachAccount: any) => eachAccount.roleType == 'member' && eachAccount.name != '');
      let adminOwners = allUsers.filter((eachAccount: any) => (eachAccount.roleType == 'owner' || eachAccount.roleType == 'admin') && eachAccount.name != '');
      adminOwners.forEach((eachAdmin: any) => eachAdmin.isOptionDisabled = true)

      let allUserOptions = [];
      if (members.length > 0) {
        allUserOptions.push({
          name: 'Members',
          value: 'Members',
          items: members
        })
        showDropdownForUserAccess = true;
      }
      allUserOptions.push({
        name: 'Owner / Admins',
        value: 'Owner / Admins',
        items: adminOwners
      })
      const userAdmin = allUsers.filter((eachAccount: any) => eachAccount.roleType == 'owner' || eachAccount.roleType == 'admin').map((eachAccount: any) => eachAccount.id);
      const userData = {
        allUsersList: allUsers,
        showDropdownForUserAccess: showDropdownForUserAccess,
        allUserOptions: allUserOptions,
        userAdmin: userAdmin
      }
      this.USER_DATA.next(userData);
    }
  }

  /**
   * To return places service details
   *
   * Run a query to get place predictions. Pick the first prediction and get the place details.
   * @param searchQuery Search query string
   */
  returnPlaceDetails(searchQuery: string): Promise<PlaceResult> {
    return new Promise(async (resolve, reject) => {
      const request = {
        input: searchQuery, // The input query,
        ...GOOGLE_MAP_RESTRICTIONS,
      };
      const result = await this.autocomplete.getPlacePredictions(request);
      const firstPrediction = result.predictions[0];
      if(!firstPrediction) {
        reject(undefined)
      }
      const placesService = new google.maps.places.PlacesService(document.createElement("div"))
      placesService.getDetails({ placeId: firstPrediction.place_id }, (placeResult, placesServiceStatus) => {
        if (placeResult && placesServiceStatus === google.maps.places.PlacesServiceStatus.OK) {
          resolve(placeResult)
        } else {
          reject('ERROR IN PLACES SERVICE')
        }
      });
    })
  }

}
