import {ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {AccountInfo, AuthService, User} from "../../services/auth/auth.service";
import {RandomService} from "../../services/misc/random.service";
import {CompanyInformation, PropertySelectionService} from "../../services/base/property-selection.service";
import {BehaviorSubject, debounceTime, distinctUntilChanged, firstValueFrom, Subject} from "rxjs";
import {IS_MOBILE, PLACEHOLDER_URL, TOAST_MESSAGES} from "../../constants/constants";
import {ToastService} from "../../services/misc/toast.service";
import {CheckoutService} from "../../services/checkout/checkout.service";
import {IsActiveMatchOptions, NavigationEnd, Router} from "@angular/router";
import {StatsService} from "../../services/stats/stats.service";
import {CdkVirtualScrollViewport} from "@angular/cdk/scrolling";
import {StatsType} from "../../interfaces/stats.type";
import {AmplitudeService} from "../../services/events/amplitude.service";
import {NgbDropdown, NgbPopover} from "@ng-bootstrap/ng-bootstrap";
import {isEmpty, uniqBy} from 'lodash-es';
import {addProductDetails} from "../../shared/modals/item-modals/add-item-modal/add-item-modal.component";
import {ProductService} from "../../services/misc/product.service";
import {GettingStartedService} from "../../services/misc/getting-started.service";
import {createFtuxArray} from "../../utils/common-functions";
import {productList} from "../../shared/display-data/product-carousel/constant";
import {ConfirmationService} from "primeng/api";
import {throttleTime} from "rxjs/operators";

export const ACCESS_PROPERTY_LIMIT: number = 50;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {


  @Input() set pageType(type: 'all' | 'checkout') {
    this._pageType = type;
  }

  get pageType(): 'all' | 'checkout' {
    return this._pageType;
  }

  _pageType: 'all' | 'checkout' = 'all';

  @Input() showFTUXMobile: boolean = true;
  @Input() showFTUXDesktop: boolean = true;

  // For mobile menu
  public showMobileMenu: boolean = false;

  // For account switching
  public accountSwitchMenu: boolean = false;

  isScrollingTop:any;

  // Store list of accounts for this ID
  accountsList: CompanyInformation[] = [];

  // Accounts searchbar
  scrollIndexChangeSubscriber = new Subject<void>()
  searchedAccountsCount: number = 0;
  accountListLoading: boolean = false;
  public accessPropertySkip: number = 0;
  accountsSearch = new BehaviorSubject<string>('')
  filteredAccountsList: CompanyInformation[] = [];

  currentCompany: CompanyInformation = {
    companyId: 0,
    companyName: ''
  }

  checkoutInformation: any;

  isChromePage : boolean = false;

  // To check weather current page is product carousel page
  @Input() isProductCarousel: boolean = false;

  // For all stats
  stats: StatsType | undefined;

  @ViewChild('dropdown') dropdown: NgbDropdown | undefined;
  ftuxDropdownAutoClose: boolean | 'inside' | 'outside' = true;

  @ViewChild('dropdownToggle') headerToggle: ElementRef | undefined;

  @ViewChild('mobileScroller') mobileScroller: CdkVirtualScrollViewport | undefined
  @ViewChild('desktopScroller') desktopScroller: CdkVirtualScrollViewport | undefined


  // Curated list popovers
  @ViewChild('desktopCuratedPopover') desktopCuratedPopover : NgbPopover | undefined
  @ViewChild('mobileCuratedPopover') mobileCuratedPopover : NgbPopover | undefined

  addProductModal: boolean = false;
  addProductApiCallStatus: boolean = false;

  routerLinkActiveOptions: IsActiveMatchOptions = {paths: 'subset', queryParams: 'ignored', fragment: 'ignored', matrixParams: 'exact'}
  ftuxArr = this.gettingStartedService.currentFTUXStatus
  currentActiveIndex = this.gettingStartedService.currentActiveIndex;

  mobileCurrentActiveIndexStep = 0;

  // Getting started mobile modal
  gettingMenu: boolean = false;


  // Loom video modal
  loomModal: boolean = false;
  loomContent: number = 0;

  // Curated popover display variable
  openCuratedPopover: boolean = false;

  // Top Selling products
  productSequence: number[] = [1, 11, 19, 4, 5];
  public productsList: any = productList
    .filter(product => this.productSequence.includes(product.order))
    .sort((a, b) => this.productSequence.indexOf(a.order) - this.productSequence.indexOf(b.order));
  protected readonly PLACEHOLDER_URL = PLACEHOLDER_URL;

  constructor(
    public auth : AuthService,
    public randomService: RandomService,
    public propertySelectionService: PropertySelectionService,
    private changeDetector: ChangeDetectorRef,
    public toastService: ToastService,
    public checkoutService: CheckoutService,
    public router: Router,
    public statsService: StatsService,
    public amplitudeService: AmplitudeService,
    public productService: ProductService,
    public gettingStartedService: GettingStartedService,
    private confirmationService: ConfirmationService,
  ) {

  }

  closeDropdown() {
    if (this.dropdown) {
      this.dropdown.close();
    }
  }

  ngOnInit(): void {
    this.randomService.isScrollingTop.subscribe({
      next: res => {
        this.isScrollingTop = res;
      },
      error: () => {}
    });
    // For Account search box
    this.accountsSearch.pipe(debounceTime(this.userInfo?.isMasterAdmin ? 500 : 0), distinctUntilChanged()).subscribe({
      next: async (value) => {
        this.accessPropertySkip = 0;
        if(value !== ''){
          if(this.userInfo?.isMasterAdmin) {
            this.filteredAccountsList = [];
            this.accountListLoading = true;
            const pData = await firstValueFrom(this.auth.getPermissions({skip: 0, limit: ACCESS_PROPERTY_LIMIT, search:value})).catch(err => {
              console.log(err)
            });
            this.filteredAccountsList = pData.permissions;
            this.searchedAccountsCount = pData.totalCount;
            this.accountListLoading = false;
          } else {
            this.filteredAccountsList = this.accountsList!.filter((eachAccount : any) => eachAccount.companyName.toLowerCase().includes(value.toLowerCase()) || this.userInfo?.isMasterAdmin && eachAccount.companyId.toString().includes(value.toLowerCase()));
          }
        } else {
          this.filteredAccountsList = this.accountsList;
          this.searchedAccountsCount = 0
        }
      }
    })

    // Stats Service subscription
    this.statsService.globalStats.subscribe({
      next: (response)=> {
        this.stats = response;
      }
    })

    // For currently selected account
    this.propertySelectionService.selectedCompanyInformation.subscribe({
      next: value => {
        this.currentCompany = value;
        this.changeDetector.detectChanges();
      }
    })

    const showFtuxCard = this.auth.showFtuxCard.getValue()

    this.openCuratedPopover = !isEmpty(this.userData?.curatedList) && !!this.userData?.curatedList?.showTooltip
    if(this.openCuratedPopover && !showFtuxCard) {
      this.openCuratedListPopover()
    }

    // For list of available accounts
    this.propertySelectionService.availableCompanyList.subscribe({
      next: (value) => {
        this.accountsList = value;
        this.filteredAccountsList = value
        this.changeDetector.detectChanges();
      }
    })

    this.checkoutService.headerInformation.subscribe({
      next: (value) => {
        this.checkoutInformation = value;
        this.changeDetector.detectChanges();
      }
    })

    if (window.location.href.includes('onboarding/chromedownload')){
      this.isChromePage = true;
    }

    this.router.events.subscribe({
      next: events => {
        if (events instanceof NavigationEnd){
          if(!IS_MOBILE) {
            this.closeGettingStarted();
          }
          this.isChromePage = events.urlAfterRedirects.includes('onboarding/chromedownload');
        }
      }
    });

    this.gettingStartedService.ftuxStatus.subscribe({
      next: (value) => {
        this.ftuxArr = value
        this.currentActiveIndex = this.gettingStartedService.currentActiveIndex
        if(this.gettingStartedService.showFtuxTemporarily) {
          this.mobileCurrentActiveIndexStep = 4
        } else {
          this.mobileCurrentActiveIndexStep = this.gettingStartedService.currentActiveIndex
        }
      }
    })

    const isUserReactivated = this.auth.user.getValue()?.reactivatedUser;
    if (showFtuxCard || isUserReactivated) {
      if (isUserReactivated) {
        this.isFtuxOpenedManually = true;
      }

      setTimeout(() => {
        if (this.dropdown && !this.dropdown.isOpen()) {
          this.dropdown?.open();
        }
        this.auth.showFtuxCard.next(false);
      }, 1000);
    }


    this.scrollIndexChangeSubscriber.pipe(throttleTime(500)).subscribe({
      next: async () => {
        this.accountListLoading = true;
        this.accessPropertySkip += ACCESS_PROPERTY_LIMIT;
        const accounts: any = await firstValueFrom(this.auth.getPermissions({skip: this.accessPropertySkip, limit: ACCESS_PROPERTY_LIMIT, search: this.accountsSearch.getValue()})).catch(err => {
          console.log(err)
        });
        this.filteredAccountsList = uniqBy([...this.filteredAccountsList, ...accounts.permissions], 'companyId');
        this.accountListLoading = false;
      }
    })

  }



  get userData(): User | null {
    return this.auth.user.getValue()!
  }

  get userInfo(): AccountInfo | undefined {
    return this.userData?.account!
  }

  onLogout() {
    this.auth.logoutUser();
  }

  /**
   * Change Account from Dropdown
   * @param company Company information
   * @param company.companyId Company ID
   */
  changeAccount(company: CompanyInformation) {
    if(company.companyId != this.currentCompany.companyId){
      const requestBody = {
        companyId: company.companyId,
      }
      this.propertySelectionService.setActiveCompanyAndProperty(requestBody).subscribe({
        next: () => {
          this.statsService.updateGlobalStats();
          this.showMobileMenu = false;
          this.closeHeaderMenu()
          this.accountSwitchMenu = false;
          document.body.classList.remove('overflow-scroll');
          this.toastService.showSuccessToast(TOAST_MESSAGES.ACCOUNT_CHANGE_SUCCESS(company.companyName))
          this.auth.updateSelectedPropertyInformation()
        },
        error: () => {

        }
      });
    } else {
      this.closeHeaderMenu()
      this.showMobileMenu = false;
      document.body.classList.remove('overflow-scroll')
      this.accountSwitchMenu = false;
    }
  }

  /**
   * Function to close header
   */
  closeHeaderMenu(){
    this.accountSearch('');
    this.headerToggle?.nativeElement.click();
  }

  menuBtnClick(closeMenu: boolean = false){
    if(closeMenu) {
      this.showMobileMenu = false;
    } else {
      this.showMobileMenu = !this.showMobileMenu;
    }
    this.accountsSearch.next('')
    this.accountSwitchMenu = false;

    if(this.showMobileMenu){
      document.body.classList.add('overflow-scroll')
    } else {
      document.body.classList.remove('overflow-scroll')
    }
  }


  /**
   * On help center click
   * @param platform Platform on which help center is clicked to hide menu
   */
  helpCenterClick(platform: 'desktop' | 'mobile'){
    if(platform == 'desktop'){
      this.closeHeaderMenu()
    } else {
      this.menuBtnClick()
    }

    this.amplitudeService.helpCenterClickEvent('account', this.userData!)
    // Show space 'help' in Intercom
    // if(environment.intercom){
    //   Intercom('showSpace', 'help')
    // } else {
    //   console.log('Intercom help center shown in prod')
    // }
  }

  /**
   * On Install shopping assistant click
   */
  chromeExtensionDownloadClick() {
    this.closeHeaderMenu();
    this.amplitudeService.extensionDownloadClickEvent('account_dropdown', this.userData!);
  }

  /**
   * To handle dropdown open/close event
   */
  handleOpenChangeDropdown(){
    this.desktopScroller?.scrollToIndex(0);
    this.mobileScroller?.scrollToIndex(0);
    this.accountSwitchMenu = false;
    // this.showMobileMenu = false;
    this.accountsSearch.next('');
  }

  /**
   * Search account and restore scroll to index 0
   * @param event search event
   */
  accountSearch(event: string){
    this.desktopScroller?.scrollToIndex(0);
    this.mobileScroller?.scrollToIndex(0);
    this.accountsSearch.next(event);
  }

  @HostListener('window:resize', ['$event'])
  resizeEventLister(){
    this.desktopScroller?.checkViewportSize()
    this.mobileScroller?.checkViewportSize()
  }

  get totalCredits() {
    return this.stats?.refundCredit! + this.stats?.availableCredits! + this.stats?.bonusCredit! + this.stats?.showroomCommission!
  }

  /**
   * Track account dropdown open in amplitude
   */
  accountDropdownOpenEvent() {
    const eventName = 'account_click';
    const eventObject = {
      user_id: this.userData?.account.id,
      account_id: this.userData?.account.activeCompanyId,
    }

    this.amplitudeService.trackEvent(eventName, eventObject)
  }


  /**
   * Switch account button click event tracker
   */
  switchAccountEvent() {
    this.accessPropertySkip = 0;
    this.filteredAccountsList = this.accountsList;
    const eventName = 'switch_account_click';
    const eventObject = {
      user_id: this.userData?.account.id,
      account_id: this.userData?.account.activeCompanyId,
    }

    this.amplitudeService.trackEvent(eventName, eventObject)
  }

  /**
   * Header tab click event
   * @param tabType Tab Clicked
   * @param location Click event location
   */
  tabClickEvent(tabType: 'brands' | 'quotes' | 'orders' | 'wishlist' | 'cart' | 'showroom' | 'shop', location: 'hamburger' | 'bottom_nav' | 'top_nav') {
    const tabMap: {[p: string]: string} = {
      'brands': 'brands_tab_click',
      'quotes': 'quotes_tab_click',
      'orders': 'orders_tab_click',
      'wishlist': 'wishlist_tab_click',
      'cart': 'cart_tab_click',
      'showroom': 'showroom_tab_click',
    }

    if (tabMap[tabType]) {
      const eventName = tabMap[tabType];
      const eventObject = {
        user_id: this.userData?.account.id,
        account_id: this.userData?.account.activeCompanyId,
        item_count: tabType == 'cart' ? this.stats?.cartQuantity : tabType == 'wishlist' ? this.stats?.wishlistQuantity : undefined,
        location,
      }
      this.amplitudeService.trackEvent(eventName, eventObject)
    }
  }

  /**
   * Click event handler for referral credits pill click
   *
   * If user does not have any credits, track amplitude event and navigate user to referral page
   */
  referralHeaderClick(deviceType: 'desktop' | 'mobile', event?: any) {
    if(this.totalCredits == 0) {
      const eventName = 'credits_pill_click'
      const eventObject = {
        user_id: this.userData!.account.id,
        account_id: this.userData!.account.activeCompanyId,
        credit_quantity: this.totalCredits,
      }
      if(deviceType === 'mobile') {
        this.menuBtnClick()
      }
      this.amplitudeService.trackEvent(eventName, eventObject)
      this.router.navigate(['/referral']).then(() => {
        if(event) {
          // Hide tooltip using visibility hiddenn
          const elem = document.getElementById('zero-credits-tooltip') as HTMLDivElement
          elem.setAttribute('style', 'visibility: hidden;')
          event.target!.addEventListener('mouseleave', () => {
            // Remove style after mouse leave and remove event listener as well
            elem.setAttribute('style', '')
            event.target?.removeEventListener('mouseleave', () => {})
          })
        }

      })
    }
  }

  /**
   * To open curated list popover
   */
  openCuratedListPopover() {
    if(this.randomService.onMobilePhone) {
      // Open mobile popover
      this.mobileCuratedPopover?.open()
    } else {
      // Open desktop popover
      this.desktopCuratedPopover?.open()
    }
  }

  /**
   * Close both mobile and desktop popovers
   */
  closePopover() {
    this.mobileCuratedPopover?.close();
    this.desktopCuratedPopover?.close();
  }

  /**
   * Call api to set showTooltip to false for the selected company
   */
  handleCuratedPopoverHide() {
    if(!this.openCuratedPopover) {
      const requestBody = {
        companyId: this.userData?.account.activeCompanyId,
        status: false,
      }
      this.auth.updateShowCuratedPopover(requestBody).subscribe({
        next: () => {
          this.auth.updateSelectedPropertyInformation();
        },
        error: (err) => {
          console.log(err)
        }
      })
    }
  }


  /**
   * Open Add product modal
   */
  openAddProductModal(): void{
    this.addProductModal = true;
  }

  /**
   * Add product modal submit event
   * @param event
   */
  addProductSubmitEvent(event: addProductDetails) {
    let addType = event.addProductInfo!
    if(addType) {
      const formData = event
      const requestBody = {
        url: formData.url,
        productName: formData.productName,
        quantity: formData.quantity,
        variant: formData.variant,
        productImageUrl: formData.productImageUrl,
        companyId: ['cart', 'wishlist'].includes(addType) ? this.propertySelectionService.selectedCompanyInformation.getValue().companyId : undefined,
        orderId: addType === 'quote' ? formData.newQuoteInfo?.id! : undefined,
        unixVersion: addType === 'quote' ? formData.newQuoteInfo?.unixVersion! : undefined
      }
      this.addProductApiCallStatus = true;
      this.randomService.updateLoader(true)
      this.productService.addProduct(addType, requestBody).subscribe({
        next: (response) => {
          console.log(response)
          const eventData = {
            ...formData,
            order_id: addType === 'new-quote' ? response.id : addType === 'quote' ? formData.newQuoteInfo?.id : undefined,
          }
          this.statsService.updateGlobalStats();

          // Trigger when add type is quote
          this.amplitudeService.addItemEvent(true, eventData, addType, this.userData!)
          this.toastService.showSuccessToast(TOAST_MESSAGES.CART_NEW_ITEM_SUCCESS);
          this.addProductApiCallStatus = false;

          // Current URL
          const currentUrl = this.router.routerState.snapshot.url
          const nextUrl = addType === 'new-quote' ? `/quotes/detail/${response.orderId}` : addType === 'quote' ? `/quotes/detail/${formData.newQuoteInfo?.orderId}` : `/${addType}`
          this.auth.setAccountFtuxData(createFtuxArray(this.auth.getAccountFtuxData(this.userData!), 2, true));

          if(currentUrl === nextUrl) {
            this.randomService.itemAdded.next(true)

            // Only update options if there is a new quote created
            if(addType === 'new-quote') {
              this.randomService.optionsChanged.next(true)
            }
          } else {
            // Go to new URL
            this.router.navigate([nextUrl]).then()
          }
          this.addProductModal = false
          this.randomService.updateLoader(false);
        },
        error: (err) => {
          let eventData = {
            ...formData,
            order_id: addType === 'new-quote' ? undefined : addType === 'quote' ? formData.newQuoteInfo?.id : undefined,
          }
          this.addProductApiCallStatus = false
          this.randomService.updateLoader(false)
          this.toastService.showErrorToast(err)
          this.amplitudeService.addItemEvent(false, eventData, addType, this.userData! ,err)
        }
      })

    }


  }


  isScrolled1: boolean = false;
  isScrollingTop1: boolean = true;
  lastScrollPosition: number = 0;

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const currentScrollPosition = window.pageYOffset;
    const windowHeight = window.innerHeight;
    const documentHeight = document.body.offsetHeight;

    if (currentScrollPosition > this.lastScrollPosition) {
      // Scrolling down
      this.isScrollingTop1 = false;
    } else {
      // Scrolling up
      this.isScrollingTop1 = true;
    }

    if (currentScrollPosition + windowHeight >= documentHeight) {
      this.isScrollingTop1 = false;

      window.innerHeight = windowHeight - 50; // Adjust as needed
    }
    // if (currentScrollPosition + windowHeight === documentHeight + 50) {
    //   this.isScrollingTop1 = true;
    // }

    this.lastScrollPosition = currentScrollPosition;
    this.isScrolled1 = currentScrollPosition > 0;
  }

  /**
   * Update which card is being shown
   * @param index Index
   */
  updateCurrentActiveIndex(index: number) {
    if(index !== this.currentActiveIndex) {
      this.amplitudeService.ftuxCardStepExpandClick(index, this.gettingStartedService.currentActiveIndex, this.userData!)
      this.currentActiveIndex = index;
      document.getElementsByClassName('ftux_card').item(index)?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' })
      document.getElementsByClassName('ftux_card').item(this.gettingStartedService.currentActiveIndex)?.classList.remove('open_card')
    }
  }


  isFtuxOpenedManually = false; //This variable should only be used in case of showing the ftux card forcefully from menu/reactivated user


  openGettingStarted() {
    // if(this.router.url.includes('/showroom')){
    //   this.router.navigateByUrl('/').then();
    // }
    this.isFtuxOpenedManually = true;
    this.gettingMenu = true;
    setTimeout(() => {
      this.dropdown?.open();
    })
  }

  closeGettingStarted(){
    this.isFtuxOpenedManually = false;
    this.gettingMenu = false;
  }

  showConfirmation(type: 'desktop' | 'mobile'){
    this.ftuxDropdownAutoClose = false;
    const message = type === 'desktop' ? 'You can always access the guide from your account dropdown. ':'You can always access the guide from the menu. ';
    this.confirmationService.confirm({
      header: 'Hide the Getting Started Guide?',
      message,
      acceptLabel: 'Yes',
      acceptButtonStyleClass: 'primary_btn btn_md dialog_dont_show_btn',
      rejectLabel: 'Cancel',
      rejectButtonStyleClass: 'outline_btn btn_md dialog_dont_show_btn',
      acceptIcon: 'false',
      rejectIcon: 'false',
      defaultFocus: 'none',
      accept: () => {
        this.ftuxDropdownAutoClose = true;
        this.auth.updateShowFtux();
        this.closeGettingStarted();
        document.body.classList.remove('overflow');
      },
      reject: () => {
        this.ftuxDropdownAutoClose = true;
        this.closeGettingStarted();
        document.body.classList.remove('overflow');
      }
    })
  }

  /**
   * Event handler for which step's CTA was clicked
   * @param event Click event
   * @param index Step index
   */
  ftuxStepCtaClick(event: any, index: number) {
    this.amplitudeService.ftuxCardCtaClick(index, this.gettingStartedService.currentActiveIndex, this.userData!)
    if(!!index && !event.ctrlKey) {
      event.preventDefault();
      this.openLoomModal(index);
    }
  }

  /**
   * Event handler for ftux card dropdown open/close
   * @param event Card open/close event
   */
  ftuxCardOpenChangeHandler(event: boolean) {
    if(!event && !IS_MOBILE){
      this.closeGettingStarted();
    }
    //Dont send default analytics events in case all steps are true and the ftux is opend from menu.
    if(this.ftuxArr.every((status) => status === true)){
      return;
    }

    this.currentActiveIndex = this.gettingStartedService.currentActiveIndex;
    if(event) {
      // Card open
      this.amplitudeService.ftuxCardOpenClick(this.gettingStartedService.currentActiveIndex, this.userData!)

      const scrollElement = this.currentActiveIndex + 1 >= 4 ? 4 : this.currentActiveIndex
      const elements = document.getElementsByClassName('ftux_card')
      elements[this.gettingStartedService.currentActiveIndex].classList.add('open_card')
      setTimeout(() => {
        elements[scrollElement].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' })
      }, 600);
    } else {
      // Card close
      this.amplitudeService.ftuxCardCloseClick(this.gettingStartedService.currentActiveIndex, this.userData!)
      document.getElementsByClassName('ftux_card')[this.gettingStartedService.currentActiveIndex].classList.remove('open_card')
      if(this.openCuratedPopover) {
        if(!this.randomService.onMobilePhone) {
          setTimeout(()=> {
            this.openCuratedListPopover()
          }, 300)
        }
        this.openCuratedPopover = false;
      }
    }
  }

  /**
   * Un focus button on dropdown open
   * @param event
   */
  dropdownOpenHandler(event: any) {
    this.dropdown?.open()
  }


  /**
   * Handle getting started modal open
   */
  gettingBtnClick(){
    this.gettingMenu = true;
    document.body.classList.add('overflow')
    this.amplitudeService.ftuxCardOpenClick(this.gettingStartedService.currentActiveIndex, this.userData!)
  }

  /**
   * To open loom modal with video content
   * @param index Index of video to be opened
   */
  openLoomModal(index: number) {
    this.loomContent = index;
    this.loomModal = true;
  }


  /**
   * Get company list on scroll
   * @param event
   */
  async scrollIndexChange(event: any) {
    const totalAccessCount = this.accountsSearch.getValue() ? this.searchedAccountsCount :this.auth.totalAccessAccountCount.getValue();
    const tolerance = 10;
    if(this.userInfo?.isMasterAdmin && event > 0 && (event + tolerance) >= this.filteredAccountsList.length && totalAccessCount > this.filteredAccountsList.length) {
      this.scrollIndexChangeSubscriber.next()
    }
  }

}


