import {Component, EventEmitter, OnDestroy, Output} from '@angular/core';
import {ProductService} from "../../../services/self-serve/product.service";
import {SelfServeService} from "../../../services/self-serve/self-serve.service";
import {Router} from "@angular/router";
import {PropertyService} from "../../../services/self-serve/property.service";
import {ToastService} from "../../../services/misc/toast.service";
import {RandomService} from "../../../services/misc/random.service";
import {SpacesService} from "../../../services/self-serve/spaces.service";
import {ManagementService} from "../../../services/management/management.service";
import {AuthService} from "../../../services/auth/auth.service";
import {AmplitudeService} from "../../../services/events/amplitude.service";
import {ShareService} from "../../../services/self-serve/share.service";
import {SHOWROOM_SETUP_UPDATE} from "../../../interfaces/property.type";
import {TOAST_MESSAGES} from "../../../constants/constants";
import {firstValueFrom, Subscription} from "rxjs";
import {CustomSpaceFormType} from "../../../shared/modals/self-serve/add-space-modal/add-space-modal.component";
import {googleAutoComplete} from "../../../utils/form-utils";


export const MAX_PROPERTIES_STEPS = 6;
export const MAX_SPACES_STEPS = 5;
export const MAX_SHARE_STEPS = 4;
export const IMAGE_ACCEPTED_FORMATS = ['jpg', 'jpeg', 'png', 'webp', 'heic'];

export const SUGGESTED_SPACES: {heading: string, type: string, spaces: string[]}[] =  [
  {
    heading: 'Common Spaces',
    type: 'commonSpaces',
    spaces: ['Living Room', 'Family Room', 'Kitchen' ,'Dining Room', 'Game Room', 'Office', 'Loft', 'Entryway', 'Laundry Room', ]
  },
  {
    heading: 'Bedrooms',
    type: 'bedrooms',
    spaces: ['Primary Bedroom', 'Guest Bedroom', 'Bedroom', 'Bedrooms', 'Bedroom 1', 'Bedroom 2', 'Bedroom 3', 'Bedroom 4', 'Bedroom 5',]
  },
  {
    heading: 'Bathrooms',
    type: 'bathrooms',
    spaces: ['Primary Bathroom', 'Guest Bathroom', 'Bathroom' ,'Bathrooms', 'Powder Room', 'Bathroom 1', 'Bathroom 2', 'Bathroom 3', 'Bathroom 4', 'Bathroom 5',]
  },
  {
    heading: 'Outdoor',
    type: 'outdoor',
    spaces: ['Outdoor Area', 'Patio', 'Deck', 'Backyard', 'Balcony', 'Pool', 'Porch', ]
  },
]

@Component({
  selector: 'app-self-serve-footer',
  templateUrl: './self-serve-footer.component.html',
  styleUrls: ['./self-serve-footer.component.scss'],
})
export class SelfServeFooterComponent implements OnDestroy{


  nextConfirmation: boolean = false;

  _nextLoading: boolean = false;
  set nextLoading(value: boolean) {
    if(value) {
      this._nextLoading = value;
    } else {
      setTimeout(() => {
        this._nextLoading = value;
      }, 200)
    }
  }
  get nextLoading(): boolean {
    return this._nextLoading
  }

  // To block back button multiple API calls
  _backLoading: boolean = false;
  set backLoading(value: boolean) {
    if(value) {
      this._backLoading = value;
    } else {
      setTimeout(() => {
        this._backLoading = value;
      }, 200)
    }
  }
  get backLoading(): boolean {
    return this._backLoading
  }

  // To get current save and exit state
  saveAndExitState: boolean = false;

  // To get current preview state
  preview: boolean = false;

  //condition to show skip loading on optional screens
  _skipLoading: boolean = false;
  get skipLoading(): boolean {
    return this._skipLoading;
  }
  set skipLoading(value: boolean) {
    if(value) {
      this._skipLoading = value;
    } else {
      setTimeout(() => {
        this._skipLoading = value;
      }, 200)
    }
  }


  saveAndExitSubscription: Subscription;
  previewSubscription: Subscription;

  @Output() previewApiCallCompleted = new EventEmitter<void>()

  constructor(
    public selfServeService: SelfServeService,
    public router: Router,
    public propertyService: PropertyService,
    public toastService: ToastService,
    public randomService: RandomService,
    public spacesService: SpacesService,
    public managementApiService: ManagementService,
    public auth: AuthService,
    public amplitudeService: AmplitudeService,
    public productService: ProductService,
    public shareService: ShareService,
  ) {
    this.saveAndExitSubscription = this.selfServeService._SAVE_AND_EXIT.subscribe({
      next: async (state) => {
        this.saveAndExitState = state;
        if (state){
          await this.saveAndExitClick();
          this.router.navigate(['/showroom']).then(() => {
            this.selfServeService._SAVE_AND_EXIT.next(false);
            this.selfServeService.resetShowroomSetup();
            this.randomService.updateLoader(false);
          })
        }
      }
    })

    this.previewSubscription = this.selfServeService._PREVIEW.subscribe({
      next: async (state) => {
        if(!this.preview) {
          this.preview = state;
          if(state) {
            await this.previewClick();
          }
        }
      }
    })
  }

  /**
   * Show skip button on optional changes screens
   */
  get showSkipButton(){
    return (this.selfServeService.CURRENT_STEP === 'property' && (this.selfServeService.PROPERTY_PROGRESS > 3 && this.selfServeService.PROPERTY_PROGRESS <= MAX_PROPERTIES_STEPS)) ||
           (this.selfServeService.CURRENT_STEP === 'spaces' && (this.selfServeService.SPACE_PROGRESS === 4 || this.selfServeService.SPACE_PROGRESS === MAX_SPACES_STEPS))
  }

  /**
   * Hide back button
   */
  hideBackButton() {
    return this.selfServeService.CURRENT_STEP === 'getting-started' ||
      (this.selfServeService.CURRENT_STEP === 'property' && (this.selfServeService.PROPERTY_PROGRESS === 2 || this.selfServeService.PROPERTY_PROGRESS === 3)) ||
      (this.selfServeService.CURRENT_STEP === 'share' && this.selfServeService.SHARE_PROGRESS > 2);
  }

  /**
   * Back click within sections
   */
  backClick(event: any) {
    // const t = document.getElementById('animate-wrpr')!;
    // t.classList.add('animate_content');
    // setTimeout(()=> {                           // <<<---using ()=> syntax
    //   const t = document.getElementById('animate-wrpr')!;
    //   t.classList.remove('animate_content');
    // }, 1000);

    event?.target?.blur?.()
    if(this.skipLoading || this.nextLoading){
      return;
    }

    // Property Back click
    if (this.selfServeService.CURRENT_STEP === 'property') {
      if (this.selfServeService.PROPERTY_PROGRESS === 0) {
        this.router.navigate([`/showroom/setup/getting-started`]).then();
      } else if (this.selfServeService.PROPERTY_PROGRESS === 1) {
        this.selfServeService.resetShowroomSetup(false);
      } else {
        if (this.propertyService.PROPERTY_LIST_COUNT.getValue() === 0 && this.selfServeService.PROPERTY_PROGRESS === 2) {
          this.selfServeService.resetShowroomSetup();
        } else if(this.selfServeService.PROPERTY_PROGRESS === 3) {
          this.selfServeService.PROPERTY_PROGRESS = 3;
          return;
        } else {
          if (!!this.selfServeService.propertyId && this.selfServeService.propertyId !== -1) {
            if(!this.backLoading) {
              this.goBackAndFetchPropertyData()
            }
          }
          this.selfServeService.SPACE_PROGRESS = 0;
          this.selfServeService.SHARE_PROGRESS = 0;
          this.selfServeService.PROPERTY_PROGRESS = this.selfServeService.PROPERTY_PROGRESS <= 0 ? 0 : this.selfServeService.PROPERTY_PROGRESS - 1;
        }
      }
    }

    // Space Back click
    if (this.selfServeService.CURRENT_STEP === 'spaces') {
      if (this.selfServeService.SPACE_PROGRESS === 1) {
        this.router.navigate([`/showroom/setup/property`]).then(() => {
          this.selfServeService.SHARE_PROGRESS = 0;
          this.selfServeService.SPACE_PROGRESS = 0;
          this.selfServeService.PROPERTY_PROGRESS = MAX_PROPERTIES_STEPS;
          this.selfServeService.setSessionStorageState();
        });
      } else if (this.selfServeService.SPACE_PROGRESS === 5 && this.productService.FAVORITE_PRODUCT_COUNT >= 5) {
        const preventSkippingFavourite = sessionStorage.getItem('preventSkippingFavourite');
        if(preventSkippingFavourite) {
          this.selfServeService.SPACE_PROGRESS = 4;
        } else{
          this.selfServeService.SPACE_PROGRESS = 3;
        }
      } else {
        this.selfServeService.SPACE_PROGRESS = this.selfServeService.SPACE_PROGRESS <= 0 ? 0 : this.selfServeService.SPACE_PROGRESS - 1;
      }
    }

    // Share Back click
    if (this.selfServeService.CURRENT_STEP === 'share') {
      if (this.selfServeService.SHARE_PROGRESS === 1) {
        this.router.navigate([`/showroom/setup/spaces`]).then(() => {
          this.selfServeService.PROPERTY_PROGRESS = MAX_PROPERTIES_STEPS;
          this.selfServeService.SHARE_PROGRESS = 0;
          this.selfServeService.SPACE_PROGRESS = MAX_SPACES_STEPS;
          this.selfServeService.setSessionStorageState()
        });
      } else {
        this.selfServeService.SHARE_PROGRESS = this.selfServeService.SHARE_PROGRESS <= 0 ? 0 :this.selfServeService.SHARE_PROGRESS - 1;
      }
    }
  }

  /**
   * Next click within sections
   * @param event
   */
  async nextClick(event: any) {
    // const t = document.getElementById('animate-wrpr')!;
    // t.classList.add('animate_content');
    // setTimeout(()=>{                           // <<<---using ()=> syntax
    //   const t = document.getElementById('animate-wrpr')!;
    //   t.classList.remove('animate_content');
    // }, 1000);

    event?.target?.blur?.()
    if(this.skipLoading || this.backLoading){
      return;
    }

    if (this.selfServeService.CURRENT_STEP === 'getting-started') {
      this.router.navigate([`/showroom/setup/property`]).then(() => {
	      this.selfServeService.SPACE_PROGRESS = 0;
        this.selfServeService.SHARE_PROGRESS = 0;
        this.selfServeService.PROPERTY_PROGRESS = 0;
      });
    }

    // Property Next click
    if (this.selfServeService.CURRENT_STEP === 'property') {
      // Save changes for current step?
      if (this.selfServeService.PROPERTY_PROGRESS < 2) {
        if (this.selfServeService.PROPERTY_PROGRESS === 1 && !!this.selfServeService.propertyId) {
          if(!this.nextLoading) {
            if (this.selfServeService.propertyId === -1) {
              this.nextLoading = true;
              await this.selfServeService.getUsersList();
              this.selfServeService.PROPERTY_PROGRESS += 1;
              this.nextLoading = false;
            } else {
              this.nextLoading = true;
              this.selfServeService.PROPERTY_PROGRESS = this.selfServeService.PROPERTY_PROGRESS + 1;
              this.nextLoading = false;
            }
          }
        }
        if (this.selfServeService.PROPERTY_PROGRESS === 0) {
          if(!this.nextLoading) {
            if(this.propertyService.PROPERTY_LIST_COUNT.getValue() === 0) {
              this.nextLoading = true;
              this.selfServeService.propertyId = -1;
              await this.selfServeService.getUsersList();
              this.selfServeService.PROPERTY_PROGRESS = this.selfServeService.PROPERTY_PROGRESS + 2;
              this.nextLoading = false;
            } else {
              this.nextLoading = true;
              this.selfServeService.PROPERTY_PROGRESS = this.selfServeService.PROPERTY_PROGRESS + 1;
              this.nextLoading = false;
            }
          }

        }
      } else {
        if (!this.nextLoading) {
          this.nextLoading = true;
          this.selfServeService.PROPERTY_PROGRESS == 2
            ? await this.goNextFromConfirmPropertyName()
            : await this.updateShowroomInfo();
        }
      }
    }

    // Spaces Next click
    if (this.selfServeService.CURRENT_STEP === 'spaces') {
      if (this.selfServeService.SPACE_PROGRESS === MAX_SPACES_STEPS) {
        if (!this.nextLoading) {
          await this.submitSpaceImages();
        }
      } else {
        if (this.selfServeService.SPACE_PROGRESS === 2) {
          if (!this.nextLoading) {
            await this.submitCreateSpaces();
          }
        } else if (this.selfServeService.SPACE_PROGRESS === 3) {
          const eventObj = {
            assigned_items : this.productService.assignedProductsCount,
            unassigned_items : this.productService.unAssignedProductsCount,
            ...this.selfServeService.userPropertyAccountId
          }
          this.amplitudeService.trackEvent('showroom_assigning_items_next_click', eventObj)
          if(this.productService.FAVORITE_PRODUCT_COUNT >= 5){
            if(!this.nextLoading) {
              this.nextLoading = true;
              await this.updateSpaceStep(this.selfServeService.SPACE_PROGRESS);
              this.selfServeService.SPACE_PROGRESS = 5;
              this.nextLoading = false;
            }
          }
          else{
            if(!this.nextLoading) {
              this.nextLoading = true;
              await this.updateSpaceStep(this.selfServeService.SPACE_PROGRESS);
              this.selfServeService.SPACE_PROGRESS = this.selfServeService.SPACE_PROGRESS + 1;
              this.nextLoading = false;
            }
          }
        } else if (this.selfServeService.SPACE_PROGRESS === 4) {
          if (!this.nextLoading) {
            await this.submitSpaceFavouriteProducts();
          }
        } else {
          if (!this.nextLoading) {
            this.nextLoading = true;
            await this.updateSpaceStep(this.selfServeService.SPACE_PROGRESS);
            this.selfServeService.SPACE_PROGRESS = this.selfServeService.SPACE_PROGRESS + 1;
            this.nextLoading = false;
          }
        }
      }
    }

    // Share Next click
    if (this.selfServeService.CURRENT_STEP === 'share') {
      if (this.selfServeService.SHARE_PROGRESS === MAX_SHARE_STEPS) {
        if(!this.nextLoading) {
          this.nextLoading = true;
          await this.updatePublishStep(this.selfServeService.SHARE_PROGRESS);
        }
        this.router.navigate([`/showroom`]).then(() => {
          this.nextLoading = false;
          this.amplitudeService.trackEvent('showroom_wizard_finish_click', this.selfServeService.userPropertyAccountId)
          this.selfServeService.resetShowroomSetup();
        });
      } else if (this.selfServeService.SHARE_PROGRESS === 1) {
        if(!this.nextLoading) {
          this.nextLoading = true;
          this.selfServeService.SHARE_PROGRESS = this.selfServeService.SHARE_PROGRESS + 1;
          await this.updatePublishStep(this.selfServeService.SHARE_PROGRESS - 1);
          this.nextLoading = false;
        }
      } else if (this.selfServeService.SHARE_PROGRESS === 2) {
        if (!this.nextLoading){
          await this.publishShowRoom()
        }
      } else if (this.selfServeService.SHARE_PROGRESS === 3) {
        if (!this.nextLoading){
          this.submitShippingAddress();
        }
      }
    }
  }

  /**
   * Go from confirm property/showroom name step
   */
  async goNextFromConfirmPropertyName() {
    if (this.selfServeService.propertyId != -1) {
      if (
        (this.selfServeService.propertyData?.orderExist ||
          this.selfServeService.propertyData?.quoteExist) &&
        this.selfServeService.propertyData.name !=
          this.selfServeService.showroomSetup.showroomName
      ) {
        this.nextConfirmation = true;
      } else if(this.selfServeService.propertyData.name != this.selfServeService.showroomSetup.showroomName) {
        await this.updateShowroomInfo(true);
      } else {
        await this.updateShowroomInfo();
      }
    } else {
      await this.submitCreateProperty();
    }
  }

  /**
   * Update showroom Info
   */
  async updateShowroomInfo(syncPropertyNameWithShowroom: boolean = false, propertyId?: number) {
    const info = this.selfServeService.showroomSetup;
    const showroomSetupStep = this.saveAndExitState || this.preview ? this.selfServeService.PROPERTY_PROGRESS - 2 : this.selfServeService.PROPERTY_PROGRESS - 1
    let requestBody: SHOWROOM_SETUP_UPDATE = {
      propertyId: !!propertyId ? propertyId : this.selfServeService.propertyId,
      showroomName: info.showroomName,
      landingImage: info.landingImage,
      logo: info.logo,
      logoLink: info.logoLink,
      address: info.address,
      showroomSetupStep: showroomSetupStep >= MAX_PROPERTIES_STEPS - 1 ? MAX_PROPERTIES_STEPS - 1 : showroomSetupStep,
      state: info.state,
      stateCode: info.stateCode ? info.stateCode : "",
      zipCode: info.zipCode,
      city: info.city ? info.city : "",
      latitude: info.latitude ? info.latitude : 0,
      longitude: info.longitude ? info.longitude: 0,
      syncPropertyNameWithShowroom: syncPropertyNameWithShowroom,
    };
    // if(!this.preview) {
    //   this.nextLoading = true;
    // }
    if(info.logoLink !== '' && this.selfServeService.PROPERTY_PROGRESS === 5){
      const eventObj = {
        property_listing_url : info.logoLink,
        ...this.selfServeService.userPropertyAccountId
      }
      this.amplitudeService.trackEvent('showroom_property_listing_url_upload', eventObj);
    }
    if(info.address !== '' && (this.selfServeService.PROPERTY_PROGRESS === 6 || requestBody.showroomSetupStep === 5) ){
      if(requestBody.latitude === 0 || requestBody.longitude === 0) {
        try {
          const response = await this.selfServeService.returnPlaceDetails(info.address);
          const address = googleAutoComplete(response);
          requestBody = {
            ...requestBody,
            ...{
              state: address.state,
              stateCode: address.state_code,
              zipCode: address.zip,
              city: address.city,
              latitude: address.latitude,
              longitude: address.longitude
            }
          }
        } catch (e) { }
      }
      this.amplitudeService.trackEvent('showroom_property_address_upload', this.selfServeService.userPropertyAccountId)
    }
    await new Promise(resolve => {
      this.propertyService.updateShowroomInfo(requestBody).subscribe({
        next: (response: any) => {
          const propertyData = response.propertyDetails;
          if (!this.saveAndExitState && !this.preview){
            const propertyList = this.propertyService.PROPERTY_LIST.getValue();
            this.selfServeService.propertyData = propertyData;
            this.propertyService.PROPERTY_LIST.next(propertyList.filter(each => each.id != propertyData.id));
            this.nextConfirmation = false;
            if (this.selfServeService.PROPERTY_PROGRESS === MAX_PROPERTIES_STEPS) {
              this.router.navigate([`/showroom/setup/spaces`]).then(() => {
                this.nextLoading = false;
                this.skipLoading = false;
                this.selfServeService.SPACE_PROGRESS = 1;
                this.selfServeService.SHARE_PROGRESS = 0;
              });
              return;
            } else {
              this.selfServeService.PROPERTY_PROGRESS = this.selfServeService.PROPERTY_PROGRESS + 1;
            }
            if (!!propertyId) {
              this.selfServeService.propertyId = propertyId;
            }
            if(this.selfServeService.PROPERTY_PROGRESS - 1 === 2){
              this.amplitudeService.screenImpressionEvent('property_cover_image', this.auth.user.getValue()!, '', 0, false, this.selfServeService.propertyId)
            }
            this.nextLoading = false;
            this.skipLoading = false;
            if(syncPropertyNameWithShowroom) {
              this.auth.updateSelectedPropertyInformation();
            }
          }
          return resolve(true);
          // this.toastService.showSuccessToast(TOAST_MESSAGES.SHOWROOM_UPDATED)
        },
        error: (err) => {
          this.nextLoading = false;
          this.skipLoading = false;
          // this.randomService.updateLoader(false);
          this.toastService.showErrorToast(err);
          return resolve(true);
        },
      });
    })
  }

  /**
   * TO submit shipping address for Minoan Touch-point
   */
  submitShippingAddress() {
    const formData = this.shareService.showroomShippingAddress;
    const requestBody = {
      propertyId: this.selfServeService.propertyId,
      shippingAddress: formData,
    };

    if (formData) {
      this.nextLoading = true;
      this.shareService.saveShowroomShippingAddress(requestBody).subscribe({
        next: async () => {
          this.amplitudeService.trackEvent('showroom_touchpoint_address_click',this.selfServeService.userPropertyAccountId)
          this.selfServeService.SHARE_PROGRESS += 1;
          await this.updatePublishStep(this.selfServeService.SHARE_PROGRESS - 1);
          this.nextLoading = false;
        },
        error: (err) => {
          this.nextLoading = false;
          this.toastService.showErrorToast(err);
        },
      });
    }
  }

  /**
   * Get completion of step from the progress bar
   * @param index Step index
   */
  getFooterCompletion(index: 0 | 1 | 2) {
    return `${this.selfServeService.selfServeProgress[index]}%`;
  }

  //============================= New Property Add Section ===============================================================
  /**
   * Add new Property
   */
  async submitCreateProperty() {
    const formData = this.selfServeService.newPropertySetup;
    this.nextLoading = true;
    await new Promise(resolve => {
      this.managementApiService.submitPropertyAdd(formData).subscribe({
        next: async (response: any) => {
          this.auth.updateSelectedPropertyInformation();
          // this.selfServeService.propertyId = response.propertyId;
          await this.getPropertyData(response.propertyId);
          this.nextLoading = false;
          return resolve(true);
        },
        error: (err) => {
          this.nextLoading = false;
          this.toastService.showErrorToast(TOAST_MESSAGES.NEW_PROPERTY_ADD_FAIL);
          return resolve(true);
        },
      });
    })

  }

  /**
   * Fetch Property List
   */
  async getPropertyData(propertyId: number) {
    const propertyInformation =  await firstValueFrom(this.propertyService.getPropertyProgress(propertyId)).catch(err => {
      console.log(err)
    })
    if(propertyInformation) {
      this.selfServeService.propertyData = propertyInformation;
      this.selfServeService.showroomSetup = this.selfServeService.setShowroomSetupPayload(propertyInformation);
      await this.updateShowroomInfo(false, propertyId);
    }
  }

  /**
   * Amplitude event to track property add
   * @param res
   */
  // buttonAddPropertyEvent(id: any) {
  //   let btnEventObj: any = {
  //     email: this.userInfo?.account.email,
  //     user_id: this.userInfo?.account.id,
  //     page: this.router.url,
  //     property_id: id,
  //   };
  //   for (let i = 0; i < (this.userInfo?.permissions.length || ''); i++) {
  //     if (this.userInfo?.account.activeCompanyId === this.userInfo?.permissions[i].companyId) {
  //       btnEventObj.client_type = this.userInfo?.permissions[i].roleType;
  //     }
  //   }
  //   this.amplitudeService.trackEvent('property_added', btnEventObj);
  // }
  //
  // get userInfo() {
  //   return this.auth.user.getValue()!
  // }
  //============================= END New Property Add Section ===========================================================

  //============================= Spaces Add/Update Section ==============================================================
  /**
   * To submit space creation
   */
  async submitCreateSpaces() {
    const formData = this.spacesService.SPACE_CREATION_FORM.getValue();

    if (formData) {
      const spaces = this.spacesService.prepareSpaceList(formData)
      const customSpaces = formData?.customSpaces ? formData?.customSpaces.filter((each: CustomSpaceFormType) => each.isSelected).map((each: CustomSpaceFormType) => ({
        title: each.name,
        spaceCategory: '',
      })) : []
      const spacesNames = [
        ...spaces,
        ...customSpaces
      ];
      if (!!spacesNames.length || this.saveAndExitState || this.preview) {
        const requestBody = {
          propertyId: this.selfServeService.propertyId,
          spaceList: spacesNames,
        };
        this.nextLoading = !this.preview;
        const eventObj = {
          spaces_selected : spacesNames,
          number_of_spaces : spacesNames.length,
          ...this.selfServeService.userPropertyAccountId
        }
        this.amplitudeService.trackEvent('showroom_spaces_selected_click', eventObj)
        await new Promise(resolve => {
          this.spacesService.createSpaces(requestBody).subscribe({
            next: async (response) => {
              await this.spacesService
                .updateSpaceList(this.selfServeService.propertyId)
                .catch((err) => {
                  console.log(err);
                });
              // if(!this.preview) {
                await this.updateSpaceStep(this.saveAndExitState || this.preview ? this.selfServeService.SPACE_PROGRESS - 1 : this.selfServeService.SPACE_PROGRESS);
                if(!this.saveAndExitState && !this.preview) {
                  await this.productService.setProductList(this.selfServeService.propertyId);
                  this.selfServeService.SPACE_PROGRESS = this.selfServeService.SPACE_PROGRESS + 1;
                }
                this.nextLoading = false;
              // }
              return resolve(true);
            },
            error: (err) => {
              this.nextLoading = false;
              this.toastService.showErrorToast(err);
              return resolve(true);
            },
          });
        })
      }
    }
  }

  /**
   * Submit Space Images
   */
  async submitSpaceImages(skip: boolean = false) {
    if(skip){
      this.skipLoading = true;
      await this.updateSpaceStep(this.selfServeService.SPACE_PROGRESS);
      this.router.navigate([`/showroom/setup/share`]).then(() => {
        this.spacesService.SPACE_IMAGES_FORM.next(undefined)
        this.selfServeService.SHARE_PROGRESS = 1;
        this.skipLoading = false;
      });
      return;
    }

    const formData = this.spacesService.SPACE_IMAGES_FORM.getValue();
    if (formData) {
      if (!!formData.length) {
        const requestBody = {
          propertyId: this.selfServeService.propertyId,
          spaceList: formData.map((each: any) => ({
            id: each.id,
            image: each.image,
          })),
        };
        this.nextLoading = !this.preview;
        await new Promise(resolve => {
          this.spacesService.saveSpacesImage(requestBody).subscribe({
            next: async () => {
              if(!this.preview) {
                await this.spacesService.updateSpaceList(this.selfServeService.propertyId).catch((err) => {
                  console.log(err);
                });
                await this.updateSpaceStep(this.saveAndExitState ? this.selfServeService.SPACE_PROGRESS - 1 : this.selfServeService.SPACE_PROGRESS);
                if(!this.saveAndExitState) {
                  this.router.navigate([`/showroom/setup/share`]).then(() => {
                    this.selfServeService.SHARE_PROGRESS = 1;
                    this.nextLoading = false;
                    this.skipLoading = false;
                  });
                }
              }
              return resolve(true);
            },
            error: (err) => {
              this.nextLoading = false;
              this.skipLoading = false;
              this.toastService.showErrorToast(err);
              return resolve(true);
            },
          });
        })

      }
    }
  }

  /**
   * To submit list of favorite products
   */
  async submitSpaceFavouriteProducts(skip: boolean = false) {
    if (skip) {
      this.skipLoading = true;
      await this.updateSpaceStep(this.selfServeService.SPACE_PROGRESS);
      this.selfServeService.SPACE_PROGRESS = this.selfServeService.SPACE_PROGRESS + 1;
      this.skipLoading = false;
      return;
    }

    const requestBody = {
      propertyId: this.selfServeService.propertyId,
      productIdList: this.productService.FAVORITE_PRODUCT_IDS,
      ourFavorite: true,
    };

    this.nextLoading = !this.preview;
    await new Promise(resolve => {
      this.productService.setFavouriteProducts(requestBody).subscribe({
        next: async (response) => {
          if(!this.preview) {
            await this.updateSpaceStep(this.saveAndExitState ? this.selfServeService.SPACE_PROGRESS - 1 : this.selfServeService.SPACE_PROGRESS);
            if(!this.saveAndExitState) {
              await this.spacesService.updateSpaceList(requestBody.propertyId);
              this.selfServeService.SPACE_PROGRESS = this.selfServeService.SPACE_PROGRESS + 1;
              this.productService.FAVORITE_PRODUCT_COUNT = this.productService.FAVORITE_PRODUCT_IDS.length;
            }
            this.nextLoading = false;
            this.skipLoading = false;
          }
          return resolve(true);
        },
        error: (err) => {
          this.nextLoading = false
          this.skipLoading = false;
          this.toastService.showErrorToast(err);
          return resolve(true);
        },
      });
    })

  }

  //============================= END Spaces Add/Update Section ==========================================================

  /**
   * Publish currently selected showroom
   */
  async publishShowRoom() {
    this.nextLoading = true;
    const body = {
      propertyId: this.selfServeService.propertyId,
    }
    try {
      await this.selfServeService.updateShowroomPublishStatus(body)
      this.amplitudeService.amplitudeShowroomPublishEvent(this.spacesService.spaceList, this.selfServeService.showroomSetup.showroomName, this.selfServeService.userPropertyAccountId)
      await this.updatePublishStep(this.selfServeService.SHARE_PROGRESS - 1);
      this.toastService.showSuccessToast(TOAST_MESSAGES.SHOWROOM_PUBLISHED(this.selfServeService.showroomSetup.showroomName))
      this.nextLoading = false;
    } catch (error: any) {
      this.toastService.showErrorToast(error);
      this.nextLoading = false;
    }
  }

  /**
   * Update publish/share steps
   * @param step
   */
  async updatePublishStep(step: number){
    const body = {
      propertyId: this.selfServeService.propertyId,
      showroomPublishStep: step
    }
    try {
      if(this.selfServeService.propertyData.showroomPublishStep <= step && step <= MAX_SHARE_STEPS) {
        await this.shareService.updatePublishStep(body).then(res => {
          this.selfServeService.propertyData.showroomPublishStep = res.showroomPublishStep
        })
      }
    } catch (error: any) {
      this.toastService.showErrorToast(error);
    }
  }

  /**
   * Update space step
   */
  async updateSpaceStep(step: number) {
    if(this.selfServeService.propertyData.spaceSetupStep <= step && step <= MAX_SPACES_STEPS) {
      await this.spacesService.updateSpaceStep({
        propertyId: this.selfServeService.propertyId,
        spaceSetupStep: step,
      }).then(res => {
        this.selfServeService.propertyData.spaceSetupStep = res.spaceSetupStep
      });
    }

  }

  /**
   * Event handler for save and exit click from header
   */
  async saveAndExitClick() {
    // Property Next click
    if (this.selfServeService.CURRENT_STEP === 'property') {
      if (!this.nextLoading) {
        this.selfServeService.PROPERTY_PROGRESS == 2
          ? await this.goNextFromConfirmPropertyName()
          : await this.updateShowroomInfo();
      }
    }

    // Spaces Next click
    if (this.selfServeService.CURRENT_STEP === 'spaces') {
      if (this.selfServeService.SPACE_PROGRESS === MAX_SPACES_STEPS) {
        if (!this.nextLoading) {
          await this.submitSpaceImages();
        }
      } else {
        if (this.selfServeService.SPACE_PROGRESS === 1) {
          await this.updateSpaceStep(this.selfServeService.SPACE_PROGRESS - 1);
        }
        else if (this.selfServeService.SPACE_PROGRESS === 2) {
          if (!this.nextLoading) {
            await this.submitCreateSpaces();
          }
        } else if (this.selfServeService.SPACE_PROGRESS === 3 && this.productService.FAVORITE_PRODUCT_COUNT >= 5) {
          this.selfServeService.SPACE_PROGRESS = 3;
        } else if (this.selfServeService.SPACE_PROGRESS === 4) {
          if (!this.nextLoading) {
            await this.submitSpaceFavouriteProducts();
          }
        }
      }
    }

    // Share Next click
    if (this.selfServeService.CURRENT_STEP === 'share') {
      if (this.selfServeService.SHARE_PROGRESS === MAX_SHARE_STEPS) {
        // this.updatePublishStep(this.selfServeService.SHARE_PROGRESS);
        this.router.navigate([`/showroom`]).then(() => {
          this.selfServeService.resetShowroomSetup();
        });
      } else if (this.selfServeService.SHARE_PROGRESS === 1) {
        // this.selfServeService.SHARE_PROGRESS = this.selfServeService.SHARE_PROGRESS + 1;
        await this.updatePublishStep(this.selfServeService.SHARE_PROGRESS - 1);
      } else if (this.selfServeService.SHARE_PROGRESS === 2) {
        // if (!this.nextLoading){
        //   this.publishShowRoom()
        // }
      } else if (this.selfServeService.SHARE_PROGRESS === 3) {
        if (!this.nextLoading){
          this.submitShippingAddress();
        }
      }
    }
  }

  /**
   * Event handler for preview click from header
   */
  async previewClick() {
    if (this.selfServeService.CURRENT_STEP === 'property') {
      await this.updateShowroomInfo();
    }

    // Spaces Next click
    if (this.selfServeService.CURRENT_STEP === 'spaces') {
      if (this.selfServeService.SPACE_PROGRESS === MAX_SPACES_STEPS) {
        await this.submitSpaceImages();
      } else {
        if (this.selfServeService.SPACE_PROGRESS === 2) {
          await this.submitCreateSpaces();
        } else if (this.selfServeService.SPACE_PROGRESS === 4) {
          await this.submitSpaceFavouriteProducts();
        }
      }
    }
    this.selfServeService.showPreviewState = true;
    this.previewApiCallCompleted.emit()
    this.preview = false;
  }

  /**
   * To fetch property data from check status API when clicking back to update property details
   */
  goBackAndFetchPropertyData() {
    this.backLoading = true;
    this.propertyService.getPropertyProgress(this.selfServeService.propertyId).subscribe({
      next: (response) => {
        this.selfServeService.showroomSetup = this.selfServeService.setShowroomSetupPayload(response);
        this.backLoading = false;
      },
      error: () => {
        this.backLoading = false;
      }
    });
  }

  /**
   * handle skip button click;
   * @param event
   */
  async onSkipButtonClick(event: any){
    event?.target?.blur();
    if(this.nextLoading || this.backLoading){
      return;
    }
    if(this.selfServeService.CURRENT_STEP === 'property'){
      if(this.selfServeService.PROPERTY_PROGRESS > 3 && this.selfServeService.PROPERTY_PROGRESS <= MAX_PROPERTIES_STEPS){
        if(!this.skipLoading){
          await this.skipUpdateShowroomInfo();
        }
      }
    } else if (this.selfServeService.CURRENT_STEP === 'spaces'){
      if(this.selfServeService.SPACE_PROGRESS === 4){
        if(!this.skipLoading){
        await this.submitSpaceFavouriteProducts(true);
        }
      } else if (this.selfServeService.SPACE_PROGRESS === MAX_SPACES_STEPS){
        if(!this.skipLoading){
          await this.submitSpaceImages(true);
        }
      }
    }
  }

  /**
   * update showroom info on click of skip button
   */
  async skipUpdateShowroomInfo(){
    this.skipLoading = true;
    const info = this.selfServeService.propertyData;
    const showroomSetupStep = this.selfServeService.PROPERTY_PROGRESS - 1
    const requestBody: SHOWROOM_SETUP_UPDATE = {
      propertyId: this.selfServeService.propertyId,
      showroomName: info.showroomName,
      landingImage: info.landingImage,
      logo: info.logo,
      logoLink: info.logoLink,
      address: info.address,
      showroomSetupStep: showroomSetupStep >= MAX_PROPERTIES_STEPS - 1 ? MAX_PROPERTIES_STEPS - 1 : showroomSetupStep,
      state: info.state,
      stateCode: info.stateCode ? info.stateCode: "",
      zipCode: info.zipCode,
      city: info.city ? info.city : "",
      latitude: info.latitude ? info.latitude : 0,
      longitude: info.longitude ? info.longitude: 0,
      syncPropertyNameWithShowroom: false
    }
    await new Promise(resolve => {
      this.propertyService.updateShowroomInfo(requestBody).subscribe({
        next: (response : any) => {
          const propertyData = response.propertyDetails;
          const propertyList = this.propertyService.PROPERTY_LIST.getValue();
          this.selfServeService.showroomSetup = propertyData;
          this.propertyService.PROPERTY_LIST.next(propertyList.filter(each => each.id != propertyData.id));
          if(this.selfServeService.PROPERTY_PROGRESS === MAX_PROPERTIES_STEPS){
            this.router.navigate([`/showroom/setup/spaces`]).then(()=>{
              this.skipLoading = false;
              this.selfServeService.SPACE_PROGRESS = 1;
              this.selfServeService.SHARE_PROGRESS = 0;
            });
            return;
          } else {
            this.selfServeService.PROPERTY_PROGRESS = this.selfServeService.PROPERTY_PROGRESS + 1;
          }
          if(this.selfServeService.PROPERTY_PROGRESS - 1 === 2){
            this.amplitudeService.screenImpressionEvent('property_cover_image', this.auth.user.getValue()!, '', 0, false, this.selfServeService.propertyId)
          }
          this.skipLoading = false;

          return resolve(true);
      }, error: (err) => {
          this.skipLoading = false;
          this.toastService.showErrorToast(err);
        }
      })
    })

  }

  /**
   * Return total space count and count ot spaces that have items
   */
  get spaceCount() {
    return this.spacesService.SPACE_WITH_ITEMS.getValue();
  }

  ngOnDestroy(): void {
    this.saveAndExitSubscription.unsubscribe();
    this.previewSubscription.unsubscribe();
  }

}
