import {Injectable, Injector} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, catchError, debounceTime, firstValueFrom, map, take} from "rxjs";
import {environment} from "../../../environments/environment";
import {Router} from "@angular/router";
import {ErrorsService} from "../customer-onboarding/errors.service";
import {PropertySelectionService} from "../base/property-selection.service";
import {AccountService} from "./account.service";
import {ToastService} from "../misc/toast.service";
import {
  LOCAL_STORAGE_INFO_VARIABLE,
  TEMPORARY_ONBOARDING_SESSION_VARIABLE,
  TOAST_MESSAGES
} from "../../constants/constants";
import {createVisitorUrlWithValidation, scrollToTop} from "../../utils/common-functions";
import {StatsService} from '../stats/stats.service';
import {FlagsmithService} from "../flagsmith/flagsmith.service";
import {EncryptionService} from "../encryption/encryption.service";
import {AmplitudeService} from "../events/amplitude.service";
import {IntercomService} from "../misc/intercom.service";
import {RandomService} from "../misc/random.service";
import {GettingStartedService} from "../misc/getting-started.service";
import {isEqual, omit} from "lodash-es";
import {
  MAX_PROPERTIES_STEPS,
  MAX_SPACES_STEPS
} from "../../showroom-self-serve/self-serve-setup/self-serve-footer/self-serve-footer.component";
import {SelfServeService} from "../self-serve/self-serve.service";
import {ACCESS_PROPERTY_LIMIT} from "../../base/header/header.component";

// Current user interface
export interface User {
  account: AccountInfo;
  chromeLoginRecently: boolean;
  curatedList?: {
    companyId?: number,
    companyName?: string,
    showTooltip?: boolean,
    referredByName?: string,
    splReferralId?: number,
  }
  permissions: any[];
  showOnboarding: boolean;
  token: string,
  reactivatedUser?: boolean
}

export interface AccountInfo {
  id: number;
  createdAt: string;
  firstName: string
  lastName: string;
  name: string;
  email: string;
  isMasterAdmin: boolean;
  isSuperAdmin: boolean;
  isBanned: boolean;
  showOnboarding: boolean;
  activeCompanyId: number;
  contactNumber?: string;
  isFirstLogin?: boolean
  amplitudeEventData?: {
    firstLoginDate: string
    referralCode: string
    referralSourceType: string
    userType: string
  },
  ftuxData?: {
    checkout: boolean,
    addedItem: boolean,
    quoteRequested: boolean,
    browseDiscounts: boolean,
    shoppingAssistant: boolean
  },
  showShowroomBadge: boolean,
  showFtux?: boolean,
}



@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user = new BehaviorSubject<User | null>(null);
  totalAccessAccountCount = new BehaviorSubject<number>(0);

  loginError = new BehaviorSubject<{showError: boolean, errorString: string }>({
    showError: false,
    errorString: '',
  })

  showFtuxCard = new BehaviorSubject<boolean>(false);

  eventProps:any={};

  constructor(
    private http: HttpClient,
    public router: Router,
    private errorService: ErrorsService,
    private propertySelectionService: PropertySelectionService,
    private accountService: AccountService,
    private toastService: ToastService,
    public intercom: IntercomService,
    private statsService: StatsService,
    private flagsmith: FlagsmithService,
    private encryptionService: EncryptionService,
    private amplitudeService: AmplitudeService,
    public randomService: RandomService,
    public gettingStartedService: GettingStartedService,
    public injector: Injector,
  ) {
  }

  /**
   * Login the user
   * @param data Login object with email and password
   * @param key Verification key
   * @param type to check the type of key
   */
  login(data: any, key: string | null = null, type?:any){
    this.http.post(`${environment.apiUrl}/public/account/login`,data).pipe(take(1)).subscribe({
      next: (response : any) => {
        setTimeout(()=>{
          this.randomService.updateLoader(false);
          this.randomService.updateLoaderText('')
        },500)
        this.updateUserState(response.data);
        this.statsService.updateGlobalStats();
        const dataWithoutPermissions = omit({...response.data}, 'permissions');
        const encryptedData = this.encryptionService.encryptData(dataWithoutPermissions)
        localStorage.setItem(LOCAL_STORAGE_INFO_VARIABLE, encryptedData);

        const accountData = response.data.account;
        const [email, minoanDbId, userType, referralCode, firstLoginDate] = [accountData.email, accountData.id, accountData.amplitudeEventData.userType, accountData.amplitudeEventData.referralCode, accountData.amplitudeEventData.firstLoginDate]
        this.amplitudeService.setUserId(email, minoanDbId, userType, referralCode, firstLoginDate);

        this.setPermissions(response.data).then();
        this.flagsmith.initFlagsmith();
        const userData = response.data
        this.gettingStartedService.currentFTUXStatus = this.getAccountFtuxData(userData.account.ftuxData)
        if(environment.intercom && !userData.showOnboarding){
          this.intercom.boot({
            app_id: environment.intercomAppKey,
            name: userData.account.name,
            email: userData.account.email,
            created_at: userData.account.createdAt,
            firstTimeUser: userData.account.isFirstLogin,
            widget: {
              "activator": "#intercom"
            },
            phone: userData.account?.contactNumber,
          });
        }
        if (environment.env === 'stage' || environment.env === 'prod'){
          this.randomService.setDataDogRumUser(userData?.account?.id,userData?.account?.email);
        }

        if(type === 'companyId'){
          if(key){
            const body={
              companyId: key
            }
            this.propertySelectionService.setActiveCompanyAndProperty(body).subscribe(
              {
                next:()=>{
                  this.updateSelectedPropertyInformation();
                },
                error:(err)=>{
                  this.toastService.showErrorToast(err);
                }
              }
            )
          }
        }
        else if(type === 'key'){
          if(key){
            const requestBody = {
              inviteKey: key
            }
            this.accountService.validateSetupKeyOldUser(requestBody).subscribe({
              next: (response: any) => {
                this.toastService.showSuccessToast(TOAST_MESSAGES.ACCOUNT_JOIN_SUCCESS(response.companyName));
                this.updateSelectedPropertyInformation()
              },
              error: (err) => {
                if(err == 'No account found with this id! Is the account deleted?'){
                  this.toastService.showErrorToast(TOAST_MESSAGES.ACCOUNT_NOT_FOUND)
                } else if(err == 'Invalid account for this invite!'){
                  this.toastService.showErrorToast(TOAST_MESSAGES.INVALID_ACCOUNT)
                } else if(err == 'The account setup link is no longer valid.') {
                  this.toastService.showErrorToast(TOAST_MESSAGES.ACCOUNT_SETUP_INVITE_REVOKED)
                } else {
                  this.toastService.showErrorToast(TOAST_MESSAGES.SETUP_KEY_INVALID)
                }
              }
            })

          }
        }

        const redirectUrl = sessionStorage.getItem('redirectUrl');
        if (redirectUrl) {
          const checkMinoanExperience = environment.production ? redirectUrl.includes('minoan') : redirectUrl.includes('minoan') || redirectUrl.includes('localhost');
          sessionStorage.removeItem('redirectUrl');
          if(checkMinoanExperience) {
            const url = createVisitorUrlWithValidation(redirectUrl, response.data.token);
            window.open(url, '_self');
          } else {
            if (!redirectUrl.includes('/onboarding/chromedownload')){
              if (redirectUrl.includes('/showroom/setup/share')){
                this.router.navigateByUrl(redirectUrl).then(()=>{
                  const selfServeService = this.injector.get(SelfServeService);
                  selfServeService.PROPERTY_PROGRESS = MAX_PROPERTIES_STEPS;
                  selfServeService.SPACE_PROGRESS = MAX_SPACES_STEPS;
                  selfServeService.SHARE_PROGRESS = 3;
                })
                return;
              }
              this.router.navigateByUrl(redirectUrl).then();
            }
          }
        } else {
          this.router.navigate(['/']).then(() => {scrollToTop();});
        }
      },
      error: (error) => {
        this.randomService.updateLoader(false);
        this.randomService.updateLoaderText('');
        if(error == 'Email or password is incorrect.'){
          // Email or password is wrong error
          this.loginErrorTracking(data.email);
        }
        this.updateLoginError(true, error);
      }
    })
  }

  /**
   * Log out the user and go back to auth
   */
  logoutUser(){
    const eventObject = {
      user_id: this.user.getValue()?.account.id!,
      account_id: this.user.getValue()?.account.activeCompanyId!,
    }
    const eventName = 'log_out_click'
    this.amplitudeService.trackEvent(eventName, eventObject);
    this.amplitudeService.setUserId(null)
    this.logoutUserSession();
  }

  /**
   * Invalidate User session on backend
   */
  logoutUserSession(){

    // After logout, remove local storage variable, navigate to auth, shut down intercom
    const afterLogout = () => {
      localStorage.removeItem(LOCAL_STORAGE_INFO_VARIABLE);
      this.router.navigate(['/auth']).then()
      this.intercom.shutdown();
    }

    this.http.post(`${environment.apiUrl}/account/logout`,{}).subscribe({
      next: () => {
        afterLogout();
      },
      error: () => {
        afterLogout();
      }
    })
  }

  /**
   * Update user state globally
   * @param data gets user info object from login
   */
  updateUserState(data: User | null) {
    this.user.next(data);
  }

  /**
   * To update error on login screen
   * @param showError Whether to show error or not
   * @param errorString Error string to be displayed
   */
  updateLoginError(showError: boolean, errorString: string = ''){
    this.loginError.next({
      errorString: errorString,
      showError: showError
    })
  }

  /**
   * To identify if user is logged in or not.
   */
  get isAuthenticated(): boolean {
    if(localStorage.getItem(LOCAL_STORAGE_INFO_VARIABLE) !== null){
      const localStorageData = localStorage.getItem(LOCAL_STORAGE_INFO_VARIABLE)!;
      let parsedData;
      try {
        parsedData = JSON.parse(localStorageData)
      } catch (e){
        parsedData = ''
      }

      // if parsedData == '', we already had encrypted data.

      const decryptedData = this.encryptionService.decryptData(localStorageData)
      if(decryptedData){
        if(!this.user.getValue()) {
          this.updateUserState(decryptedData!)
        }
      } else {
        if(typeof parsedData == "object"){
          if(!this.user.getValue()) {
            this.updateUserState(parsedData);
            const encryptedData = this.encryptionService.encryptData(parsedData)
            localStorage.setItem(LOCAL_STORAGE_INFO_VARIABLE, encryptedData)
          }
        } else {
          return false;
        }
      }
      if(!this.user.getValue()) {
        this.updateUserState(this.encryptionService.decryptData(localStorage.getItem(LOCAL_STORAGE_INFO_VARIABLE)));
      }
    } else {
      return false;
    }

    let userLoggedIn = this.user.getValue();
    return userLoggedIn !== null;

  }

  /**
   * To Identify User Already onboarded or Not
   */
  get isUserOnboarding(): boolean {
    const tempShowOnboarding = !!sessionStorage.getItem(TEMPORARY_ONBOARDING_SESSION_VARIABLE)
    return this.user.getValue()!.showOnboarding || tempShowOnboarding
  }

  /**
   * Check AccessToken API
   */
  checkAccessToken(){
    return this.http.post(`${environment.apiUrl}` + '/account/accessTokenLogin',{})
      .pipe(take(1), map((response:any) => {
        const {data} = response
        const ftux = data.account.ftuxData
        this.gettingStartedService.currentFTUXStatus = this.getAccountFtuxData(ftux)
        return response
      }), catchError((err:any) => {
        throw err
      }));
  }

  /**
   * [POST] Get Permission in case of super admin
   * @param body
   */
  getPermissions(body: any) {
    return this.http.post(`${environment.apiUrl}/account/permission`, body).pipe(
      debounceTime(500),
      take(1), map((response: any) => {
        return response.data;
      }), catchError(err => {
        throw err
      }),
    );
  }

  /**
   * Update user access on selected property and property list
   * @param userData
   */
  async setPermissions(userData: any) {
    if(userData.account.isMasterAdmin) {
      const companyData: any =  await firstValueFrom(this.getPermissions({skip: 0, limit: ACCESS_PROPERTY_LIMIT, search:''})).catch(err => {
        console.log(err)
      })
      this.totalAccessAccountCount.next(companyData.totalCount)
      this.propertySelectionService.setAllData(userData.permissions[0], companyData.permissions, userData.account.isMasterAdmin)
    } else {
      const selectedCompany = userData.permissions.find((eachCompany: any) => eachCompany.companyId == userData.account.activeCompanyId)
      this.propertySelectionService.setAllData(selectedCompany, userData.permissions)
    }
  }

  /**
   * Calls Check access token api to update relevant company and property information
   */
  updateSelectedPropertyInformation(): void{
    this.checkAccessToken().subscribe({
      next: (response: { data: User }) => {
        const dataWithoutPermissions = omit({...response.data}, 'permissions');
        const encryptedData = this.encryptionService.encryptData(dataWithoutPermissions)
        localStorage.setItem(LOCAL_STORAGE_INFO_VARIABLE, encryptedData);
        this.updateUserState(response.data)
        this.setPermissions(response.data).then();
      },
      error: () => {
        this.logoutUser();
      }
    })
  }

  loginErrorTracking(email:any){
    const eventObject = {
      screen_name: 'login',
      button_name: 'login',
      email: email,
      error: ['email_password_incorrect'],
    }
    this.amplitudeService.buttonClickEvent(eventObject);
  }

  /**
   * [POST] API to updated curated list popover status
   */
  updateShowCuratedPopover(body: any) {
    return this.http.post(`${environment.apiUrl}/update/curated/tooltip`, body).pipe(take(1),
      map((response:any) => response.data),
      catchError((error:any) => {
        throw error
      })
    );
  }

  /**
   * Get array of ftux values
   * @param ftuxData User FTUX data
   */
  getAccountFtuxData(ftuxData: any): boolean[] {
    return ftuxData ? [ftuxData.shoppingAssistant, ftuxData.browseDiscounts, ftuxData.addedItem, ftuxData.quoteRequested, ftuxData.checkout] : [false,false,false,false,false]
  }

  /**
   * Set FTUX data in User behaviour subject
   * @param ftuxDataArray Ftux data array
   * @param showFtuxTemporarily To show FTUX Card temporarily till user refreshes the page
   */
  setAccountFtuxData(ftuxDataArray: boolean[], showFtuxTemporarily: boolean = false) {
    const ftuxMap: any = {
      0: 'shoppingAssistant',
      1: 'browseDiscounts',
      2: 'addedItem',
      3: 'quoteRequested',
      4: 'checkout',
    }

    const newFtuxData = ftuxDataArray.reduce((acc: any, each, index) => {
      const key = ftuxMap[index]
      acc[key] = each
      return acc
    }, {})
    const userInfo = this.user.getValue()!;

    if(userInfo && userInfo.account && userInfo.account.ftuxData  && !isEqual(newFtuxData, userInfo.account.ftuxData)) {
      // Update ftux data in account
      this.updateUserState({
        ...userInfo,
        account: {
          ...userInfo.account,
          ftuxData: newFtuxData,
          showFtux: newFtuxData?.checkout ? false : userInfo.account.showFtux,
        }
      })
      this.gettingStartedService.showFtuxTemporarily = !userInfo.account.showFtux ? false : showFtuxTemporarily
      this.gettingStartedService.currentFTUXStatus = ftuxDataArray
    }

  }

  /**
   * Update ftux on shop now click
   */
  updateShopNowFtux() {
    const userInfo = this.user.getValue()

    if(userInfo && !userInfo.account.ftuxData?.browseDiscounts) {
      this.http.post(`${environment.apiUrl}/update/user/browse/details`, {browseDiscounts: true}).subscribe({
        next: () => {}
      })

      const oldFtuxData = [...this.getAccountFtuxData(userInfo.account.ftuxData)]
      oldFtuxData[1] = true
      this.setAccountFtuxData(oldFtuxData)
    }
  }

  updateShowFtux(){
    this.http.post(`${environment.apiUrl}/update/user/browse/details`, {showFtux: false}).subscribe({
      next: () => {
        const userInfo = this.user.getValue()
        if(userInfo){
          this.updateUserState({
            ...userInfo,
            account: {
              ...userInfo.account,
              showFtux: false,
            }
          })
        }
      }
    })
  }


  /**
   * [POST] API to update user's showroom badge status
   * @param showShowroomBadge Showroom badge status
   */
  updateShowroomBadgeApi(showShowroomBadge: boolean) {
    return this.http.post(`${environment.apiUrl}/update/showroom/badge`, {showShowroomBadge}).pipe(take(1), catchError(err => {throw err}))
  }

  /**
   * To update showroom badge status when a user opens the showroom tab
   */
  updateShowroomBadge() {
    const user = {
      ...this.user.getValue()!
    }
    if(user && user?.account && user?.account.showShowroomBadge) {
      this.updateShowroomBadgeApi(false).subscribe({
        next: () => {
          this.updateUserState({
            ...user,
            account: {
              ...user.account,
              showShowroomBadge: false
            }
          })
        }
      })
    }
    return;
  }

}
